import React from 'react';
import '../../Styles/SocialShareButton.css'

function SocialShareButton() {
    return <nav className='share'>
        <ul>
            <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://inno-scis-client.web.app/" target="_blank"><i class="fab fa-facebook-f"></i><span>Facebook</span></a></li>
            <li><a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Finno-scis-client.web.app%2F&text=" target="_blank"><i class="fab fa-twitter"></i><span>Twitter</span></a></li>
            <li><a href="https://api.whatsapp.com/send?text=%0ahttps://inno-scis-client.web.app/" target="_blank"><i class="fab fa-whatsapp-square"></i><span>Whatsapp</span></a></li>
            <li><a href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Finno-scis-client.web.app%2F&title=" target="_blank"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a></li>
        </ul>
    </nav>;
}

export default SocialShareButton;
