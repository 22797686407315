import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/AppContext';
import '../../Styles/JobSearch.css';
import CandidateCard from '../JobSearch/CandidateCard';
import SearchForm from '../JobSearch/SearchForm';

function FindCandidates() {
    const [searchField, setSearchField] = useState({})
    const [sortedData, setSortedData] = useState([])
    const [allData, setAllData] = useState([])
    const { userCredentials } = useContext(UserContext);
    const screenWidth = window.innerWidth

    useEffect(() => {
        if (!searchField?.education && !searchField?.expertise && !searchField?.location) {
            fetch('https://linkintern-backend.herokuapp.com/get-user')
                .then(res => res.json())
                .then(data => {
                    setAllData(data?.filter(info => info?.hasOwnProperty('resume')))
                    setSortedData(data?.filter(info => info?.hasOwnProperty('resume')))
                })
        }
        if (searchField?.location && searchField?.education && searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.address === searchField.location).filter(data => data.resume.education === searchField.education).filter(data => data.resume.industry === searchField.expertise))
        }
        if (searchField?.location && searchField?.education && !searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.address === searchField.location).filter(data => data.resume.education === searchField.education))
        }
        if (searchField?.location && !searchField?.education && searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.address === searchField.location).filter(data => data.resume.industry === searchField.expertise))
        }
        if (searchField?.location && !searchField?.education && !searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.address === searchField.location))
        }
        if (!searchField?.location && searchField?.education && searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.education === searchField.education).filter(data => data.resume.industry === searchField.expertise))
        }
        if (!searchField?.location && searchField?.education && !searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.education === searchField.education))
        }
        if (!searchField?.location && !searchField?.education && searchField?.expertise) {
            setSortedData(allData.filter(data => data.resume.industry === searchField.expertise))
        }
    }, [searchField])

    return (
        <>
            {
                (userCredentials?.isCompany || userCredentials?.email === 'super.admin@gmail.com') ?
                    <div className="bg-white">
                        <div className={`p-lg-5 d-block mx-auto ${screenWidth < 576 ? 'w-100' : 'w-82'}`}>
                            <h1 className="candidate-search my-4 text-center">Find the right candidate for your business</h1>
                            <SearchForm setSearchField={setSearchField} searchField={searchField} />
                            <div className="row justify-content-center align-items-center mt-4">
                                {
                                    sortedData?.map((candidate) =>
                                    <div className={`${screenWidth > 1500 ? 'col-lg-3' : 'col-lg-4 col-md-6 col-10 my-2  ' }`}>
                                    <CandidateCard candidate={candidate} />
                                    </div>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                    :
                    <Navigate to='/*' />
            }
        </>
    )
}

export default FindCandidates
