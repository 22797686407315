import React, { useContext } from "react";
import { UserContext } from "../../context/AppContext";
import defaultIcon from '../../assests/avator/avatar.jpg'

const avatars = [
  {
    name: 'female1',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female1_fegocs.png',
    gender: 'female'
  },
  {
    name: 'female2',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female2_qpbezb.png',
    gender: 'female'
  },
  {
    name: 'female3',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female3_uccfnt.png',
    gender: 'female'
  },
  {
    name: 'female5',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female5_jgyitb.png',
    gender: 'female'
  },
  {
    name: 'female6',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female6_wpcyu9.png',
    gender: 'female'
  },
  {
    name: 'female7',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female7_h7dkkf.png',
    gender: 'female'
  },
  {
    name: 'female12',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female12_m99zzt.png',
    gender: 'female'
  },
  {
    name: 'female33',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485897/female33_mb0w4j.png',
    gender: 'female'
  },
  {
    name: 'female55',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/female55_logapi.png',
    gender: 'female'
  },
  {
    name: 'female545',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/female545_qgux4d.png',
    gender: 'female'
  },
  {
    name: 'female554',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/female554_torwqt.png',
    gender: 'female'
  },
  {
    name: 'female45656',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/female45656_zs4zeu.png',
    gender: 'male'
  },
  {
    name: 'girl',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/girl_z3c6g6.png',
    gender: 'female'
  },
  {
    name: 'girl3',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/girl3_xyyszu.png',
    gender: 'female'
  },
  {
    name: 'girl3334',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/girl3334_hl4gvs.png',
    gender: 'female'
  },
  {
    name: 'male1',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/male1_wc4jr1.png',
    gender: 'male'
  },
  {
    name: 'male2',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/male2_o8skzn.png',
    gender: 'male'
  },
  {
    name: 'male3',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/male3_gbglej.png',
    gender: 'male'
  },
  {
    name: 'male4',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male4_gujr1n.png',
    gender: 'male'
  },
  {
    name: 'male5',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/male5_lupqnr.png',
    gender: 'male'
  },
  {
    name: 'male7',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485898/male7_pzomcf.png',
    gender: 'male'
  },
  {
    name: 'male8',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male8_zmecmu.png',
    gender: 'male'
  },
  {
    name: 'male9',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male9_w5pnzd.png',
    gender: 'male'
  },
  {
    name: 'male33',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male33_shmcqt.png',
    gender: 'male'
  },
  {
    name: 'male44',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male44_tl9zli.png',
    gender: 'male'
  },
  {
    name: 'male91',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male91_nv1gwc.png',
    gender: 'male'
  },
  {
    name: 'male454545',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male454545_zr0ou5.png',
    gender: 'male'
  },
  {
    name: 'male565656',
    path: 'https://res.cloudinary.com/dylntufy1/image/upload/v1643485899/male565656_x9cjkh.png',
    gender: 'male'
  }
]

const PortfolioAvatar = () => {
  const { setResumeData, resumeData } = useContext(UserContext)
  const screenWidth = window.innerWidth;

  const handleResumeChange = value => {
    const updatedResume = { ...resumeData }
    updatedResume.avatar = { ...value }
    setResumeData(updatedResume)
  }

  return (
    <>
      <div className="avater d-flex align-items-center justify-content-center">
        {resumeData?.avatar?.gender === 'female' ? <img className='w-90' src={resumeData?.avatar?.path} alt="" /> : resumeData?.avatar?.gender === 'male' ? <img className='w-90' src={resumeData?.avatar?.path} alt="" /> : <img className='w-90' src={defaultIcon} alt="" />}
      </div>

      <div className={`dropdown show d-block mx-auto text-center ${screenWidth < 450 ? 'w-80 position-relative' : 'w-40'}`}>
        <p className="text-blue pointer dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Change avater
        </p>

        <div className="dropdown-menu row align-items-center justify-content-between avatarContainer" aria-labelledby="dropdownMenuLink">
          {/* <a className="dropdown-item" onClick={() => handleResumeChange('male')}>Male</a>
          <a className="dropdown-item" onClick={() => handleResumeChange('female')}>Female</a> */}
          {
            avatars.map(data =>
              <img className="pointer col-lg-3 col-4 w-100 py-2 px-3" onClick={() => handleResumeChange(data)} src={data.path} alt={data.name} />
            )
          }
        </div>
      </div>

    </>
  );
};

export default PortfolioAvatar;
