/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import female from "../../assests/images/portfolio/femaleSm.png";
import male from "../../assests/images/portfolio/maleSm.png";
import company from '../../assests/images/portfolio/company.png'
import { UserContext } from '../../context/AppContext';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

function DashboardTopbar() {
    const { userCredentials, setUserCredentials, stBusinessDetails, setSubscription, setAllJobs, setIsCompany, isCompany, resumeData } = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()
    const isDashboard = location.pathname === '/dashboard';

    const handleLogOut = () => {
        localStorage.removeItem('@credentials')
        setUserCredentials({})
        stBusinessDetails({})
        setSubscription({})
        setAllJobs([])
        setIsCompany(false)
        navigate('/', { replace: true })
    }


    return (
        <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center pt-3'>
            <ul className="nav justify-content-end align-items-center my-3 my-lg-0">
                {
                    location.pathname === '/dashboard' && <li className="nav-item pointer mx-0">
                        <i className="far fa-bell nav-icon"></i> <sup><span className="badge badge-primary rounded-cricle">4</span></sup>
                    </li>
                }

                <li className={`nav-item dropdown ${isDashboard ? '' : 'text-right'}`}>

                    <a className="nav-link dropdown-toggle text-dark mt-0" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {
                            isCompany ?
                                <img className='w-60 p-1' src={company} alt="" />
                                :
                                resumeData?.avatar?.gender === 'female' ?
                                    <img className='w-60 p-1' src={resumeData?.avatar?.path} alt="" />
                                    :
                                    <img className='w-60 p-1' src={resumeData?.avatar?.path} alt="" />
                        }
                    </a>

                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <div className='d-flex align-items-center dropdown-item my-2'>
                            <div>
                                <h6 className='mb-0'>{isCompany ? userCredentials?.companyName : userCredentials?.fullName}</h6>
                                <small className='text-secondary'>{userCredentials?.email || "test@gmail.com"}</small>
                            </div>
                        </div>
                        <li className="dropdown-item pointer" onClick={() => handleLogOut()}>Log Out</li>
                    </div>
                </li>
                <li className="nav-item h5 mb-0 style-two font-semi-bold">
                    {isCompany ? userCredentials?.email === 'super.admin@gmail.com' ? 'Admin' : 'Employer' : 'Candidate'}
                </li>
            </ul>
        </div>
    )
}

export default DashboardTopbar
