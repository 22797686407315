import React from "react";
import "../../Styles/AllJobTwo.css";
import comp1 from '../../assests/images/new/amazon.png';
import comp2 from '../../assests/images/new/att-logo.png';
import comp3 from '../../assests/images/new/images.png';
import comp4 from '../../assests/images/new/tata.jpg';
import comp5 from '../../assests/images/new/accenture-logo.png';
import { Link } from "react-router-dom";


const recentJob = [
  {
    img: "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453",

    jobTitle: "Junior Software Engineer",
    salary: "$400 - $500 / Month",
    Adress: "Tønsberg, Norway",
  },
  {
    img: comp1,

    jobTitle: "Data Scientist",
    salary: "$600 - $800 / Month",
    Adress: "Oslo, Norway",
  },
  {
    img: comp2,

    jobTitle: "Marketing Executive",
    salary: "$400 - $500 / Month",
    Adress: "Bergen, Norway",
  },
  {
    img: comp3,

    jobTitle: "Growth Hacker",
    salary: "$400 - $600 / Month",
    Adress: "Tromsø, Norway",
  },
  {
    img: comp4,

    jobTitle: "Management Trainee",
    salary: "$500 - $600 / Month",
    Adress: "Stavanger, Norway",
  },
  {
    img: comp5,

    jobTitle: "Web Developer",
    salary: "$500 - $700 / Month",
    Adress: "Trondheim, Norway",
  },
];

const AllJobTwo = () => {
  return (
    <>
      <div className="all-job-section">
        <div className="container">
          <h6 className="j-post">All Jobs Post</h6>
          <div className="d-flex justify-content-between">
            <h3 className="f-career">
              Find your career you
              <br />
              deserve it
            </h3>
            <div>
              <Link to='/job'>
                <button className="b-button cursor-pointer">Browse All Jobs</button>
              </Link>
            </div>
          </div>

          <div className="row">
            {recentJob.map((job) => (
              <>
                <div className="col-md-4 pb-5">
                  <div class="card boxTwo-card mt-5">
                    <div class="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <img
                            src={job.img}
                            alt=""
                            className="h-logo"
                          />
                        </div>
                        <div>
                          <button className="new">New</button>
                        </div>
                      </div>
                      <h5 class="jo-title">{job.jobTitle}</h5>
                      <p className="jo-subtitle">{job.Adress}</p>
                      <div className="d-flex  justify-content-between">
                        <h6>{job.salary}</h6>
                        <Link to='/job'>
                          <h6 className="browse-job cursor-pointer">Browse Job</h6>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllJobTwo;
