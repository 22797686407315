import React from 'react';
import '../../Styles/ForEmployeeTwo.css'
import eimage from '../../assests/images/newHome/sp.png'

const ForEmployeeTwo = () => {
    return (
        <>
            <div className='container my-md-5 py-md-5 py-2 my-2'>
                <div className='forEmployee-section'>
                    <img src={eimage} className='img-fluid' alt='' />
                </div>
            </div>
        </>
    );
};

export default ForEmployeeTwo;