import React from "react";

const JobModal = ({ jobs, setIsRead }) => {
  const { jobTitle, description, requirements, industry, experience, salary } =
    jobs;
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalLong"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title job-title" id="exampleModalLongTitle">
                Hiring For {jobTitle}
              </h5>
            </div>

            <div className="modal-body">
              <h5>Job Description:</h5>
              <pre className="modal-job-description">{description}</pre>
              <h5>Job Requirements:</h5>
              <pre className="modal-job-description">{requirements}</pre>
              <h5>Experience Level:</h5>
              <p className="job-description">{experience}</p>
              <h5>Salary:</h5>
              <p className="job-description">{salary}</p>
            </div>
            <div className="modal-footer">
              {/* <button className="btn-blue px-4 d-block mr-auto">Apply</button> */}
              <button
                type="button"
                onClick={() => setIsRead(false)}
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobModal;
