import React from 'react';


const PortfolioMessage = ({ handleChange }) => {

    return (
        <div>
            <h4 className="form-title">Contents of Letter</h4>
            <div className="border-line" />
            {/* <Editor
                editorState={editorText}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
            /> */}
            <div className="form-group">
                <label for="contentLetter" className="lebel-title mt-4">
                    Contents of Letter
                </label>
                <textarea
                    onChange={handleChange}
                    id='contentLetter'
                    name='contentLetter'
                    className='w-100 form-control'
                    rows='10'
                    required
                />
            </div>

        </div>
    );
};

export default PortfolioMessage;
