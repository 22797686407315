import React from "react";
import "../../Styles/JoingTraning.css";
import course1 from "../../assests/images/course/courseOne.png";
import course2 from "../../assests/images/course/courseTwo.png";
import course3 from "../../assests/images/course/courseThree.png";
import course4 from "../../assests/images/course/courseOne.png";
import course5 from "../../assests/images/course/courseTwo.png";
import course6 from "../../assests/images/course/courseThree.png";

const courseData = [
  {
    img: course1,
    title: "UI/UX Design",
  },
  {
    img: course2,
    title: "software engineer",
  },
  {
    img: course3,
    title: "Content Writing",
  },
  {
    img: course4,
    title: "UI/UX Design",
  },
  {
    img: course5,
    title: "software engineer",
  },
  {
    img: course6,
    title: "Content Writing",
  },
];

function JoinTraining() {
  return (
    <div className="container my-3 d-flex flex-column align-items-md-center justify-content-md-center vh-100">
      <div className="row p-lg-4 p-3  align-items-center">
        {courseData.map((data) => (
          <div className="col-md-4 my-3">
            <div className="card course-card text-white">
              <img src={data.img} className="card-img" alt="..." />
              <div className="card-img-overlay">
                <h5 className="course-title">{data.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="next-button mx-3 mb-5 ml-auto d-block">See More</button>
    </div>
  );
}

export default JoinTraining;
