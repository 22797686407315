import React from 'react';

const Message = () => {
    return (
        <div>
         <h1 className='display-4 text-danger text-center py-5'>Feature coming soon...</h1>
    </div>
    );
};

export default Message;