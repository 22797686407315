import React, { useState } from 'react';
import '../../Styles/interviewModal.css';
import emailjs from "emailjs-com";
import { NotificationContainer, NotificationManager } from 'react-notifications';


const InterviewModal = ({ jobs, candidate, companyDetails }) => {


    const handleSendEmail = title => {

        const message1 = `
      We have found your resume from Inno Scis: The Intern Platform. Currently, we are hiring for the position of ${title}. Your resume has stand out from the crowd. We would like to schedule an interview with you. This will be a 30 minutes interview. To know more about our Compnay, please go to ${companyDetails?.businessDetails?.companyUrl}.`

        const message2 = `If you have any questions about the interview, please send an email to ${companyDetails?.email}`

        emailjs.send('service_xl3ng7t', 'template_71zk6s8', { candidate: candidate?.resume?.fullName, name: companyDetails?.companyName, message1: message1, message2: message2, toEmail: candidate?.email, replyEmail: companyDetails?.email }, "user_kosMS0TM9jXFWW4Cerip0")
            .then((result) => {
                setTimeout(() => {
                    NotificationManager.success('', 'Email sent successfully!', 4000);
                }, 1000)
            }, (error) => {
                console.log(error.text);
            });

    };





    return (
        <>
            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <NotificationContainer />
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title interview-modal-title" id="staticBackdropLabel">Request For Interview</h5>
                        </div>
                        <div className="modal-body">

                            {
                                jobs?.map((coJob) => <>
                                    <h5 onClick={() => handleSendEmail(coJob?.jobTitle)} className="all-job-title" data-dismiss="modal">Hiring for {coJob?.jobTitle}</h5>
                                    <hr className="border-secondary" />

                                </>)
                            }

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InterviewModal;