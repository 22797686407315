import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import '../../Styles/Dashboard.css'
import CustomStepper from '../../components/Dashboard/CustomStepper';
import CreatePortfolio from '../../pages/Portfolio/CreatePortfolio'
import Resume from '../../components/Resume/Resume';
import DashboardTopNav from '../../components/Dashboard/DashboardTopNav';

function Dashoboard() {
    const location = useLocation()

    return (
        <div className='dashboard container-fluid p-lg-0'>
            <DashboardTopNav />
            <div className='row dashboard-bg'>
                <div className='col-lg-12 px-0  dashboard-bg'>
                    <div className='right-col  mx-auto d-block'>
                        {
                            location.pathname === '/dashboard/business-details' || location.pathname === '/dashboard/post-job' || location.pathname === '/dashboard/subscription' ?
                                <CustomStepper />
                                :
                                <></>
                        }
                        {
                            location.pathname === '/dashboard/portfolio' ?
                                <div className='row justify-content-between'>
                                    <div className='col-lg-6 col-12'>
                                        <CreatePortfolio />
                                    </div>
                                    <div className='col-lg-6 col-12'>
                                        <Resume />
                                    </div>
                                </div>
                                :
                                <Outlet />
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dashoboard
