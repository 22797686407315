import React from 'react';
import PortfolioAvatar from '../../components/Portfolio/PortfolioAvatar';
import PortfolioInput from '../../components/Portfolio/PortfolioForm';
import '../../Styles/Portfolio.css';

function CreatePortfolio() {
    return (
        <div className="portfolio-card p-lg-5 p-3 my-5">
            <PortfolioAvatar />
            <PortfolioInput />
        </div>
    )
}

export default CreatePortfolio
