import React from "react";
import "../../Styles/JobCatagoryTwo.css";
import icon1 from '../../assests/images/newHome/icone-1.png'
import icon2 from '../../assests/images/newHome/icone-2.png'
import icon3 from '../../assests/images/newHome/icon3.png'
import icon4 from '../../assests/images/newHome/icon-5.png'
import icon5 from '../../assests/images/new/icon5.jpeg'
import icon6 from '../../assests/images/new/icon6.jpeg'
import icon7 from '../../assests/images/new/icon7.jpeg'
import icon8 from '../../assests/images/new/icon8.jpeg'
import { Link } from "react-router-dom";

const jobCatagory = [
  {
    img: icon1,
    title: "Sales & Marketing",
    jobCount: "1000"
  },
  {
    img: icon2,
    title: "Education",
    jobCount: "20"
  },
  {
    img: icon3,
    title: "Finance & Management",
    jobCount: "10"
  },
  {
    img: icon4,
    title: "Service",
    jobCount: "30"
  },
  {
    img: icon5,
    title: "Software Development",
    jobCount: "50"
  },
  {
    img: icon6,
    title: "Product & Engineering",
    jobCount: "10"
  },
  {
    img: icon7,
    title: "Health & Science",
    jobCount: "15"
  },
  {
    img: icon8,
    title: "Business Development",
    jobCount: "25"
  }
]

const JobCatagoryTwo = () => {
  return (
    <>
      <div className="job-category-section">
        <div className="container">
          <div className="py-md-5 py-1">
            <h6 className="job-by-category">Jobs by Categories</h6>
            <h3 className="choose-catagoryTwo for-company">
              Choose your desire <br /> category
            </h3>
          </div>
          <div className="row">
            {
              jobCatagory?.map((cate, idx) =>
                <>
                  <div className="col-md-3 mb-4 cursor-pointer">
                    <Link to='/job'>
                      <div class="job-catagory-one">
                        <img src={cate.img} alt="" className={`d-block mx-auto mb-3 ${idx < 4 ? 'card-icon' : 'card-icon-2'}`} />
                        <button className="job-count">{cate.jobCount} Jobs</button>
                        <h6 className="catagoryTwo-title">{cate.title}</h6>
                      </div>
                    </Link>
                  </div>
                </>
              )
            }
          </div>

        </div>
      </div>
    </>
  );
};

export default JobCatagoryTwo;
