import React, { useState, useEffect } from "react";

function SearchForm({ setSearchField, searchField }) {
  const screenWidth = window.innerWidth;
  const [allCountries, setAllCountries] = useState([])


  const getCountries = () => {
    fetch('https://restcountries.com/v3.1/all')
      .then(res => res.json())
      .then(data => {
        const countires = data.map(obj => obj.name.common)
        setAllCountries(countires.sort())
      })
  }

  useEffect(() => {
    getCountries()
  }, [])

  const handleSearch = (e) => {
    const updatedSearch = { ...searchField };
    updatedSearch[e.target.name] = e.target.value
    setSearchField(updatedSearch)
  }


  return (
    <div className='bg-white'>
      <div className="px-lg-5">
        <form>
          <div className="row ">
            <div className="col-md-6 py-2">
              <div
                className={`form-group mr-5 ${screenWidth < 769 ? "w-100" : "w-100"
                  }`}
              >
                <label for="location" className="lebel-title">
                  Location
                </label>
                <select onChange={handleSearch} className="form-control" id="location" name='location'>
                  <option disabled selected>Select Location</option>
                  {
                    allCountries.map(country =>
                      <option>{country}</option>
                    )
                  }
                </select>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div
                className={`form-group mr-5 ${screenWidth < 769 ? "w-100" : "w-100"
                  }`}
              >
                <label for="expertise" className="lebel-title">
                  Expertise
                </label>
                <select onChange={handleSearch} className="form-control" id="expertise" name='expertise'>
                  <option disabled selected>Select Skills</option>
                  <option>Finance, Management & Ops</option>
                  <option>Arts & Fashion</option>
                  <option>Networking Opportunities</option>
                  <option>Sales & Marketing</option>
                  <option>Health & Science</option>
                  <option>Product & Engineering</option>
                  <option>Education</option>
                  <option>Software Development</option>
                  <option>Service</option>
                  <option>Law & Journalism</option>
                </select>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div
                className={`form-group  ${screenWidth < 769 ? "w-100" : "w-100"
                  }`}
              >
                <label for="education" className="lebel-title ">
                  Education Level
                </label>
                <select onChange={handleSearch} className="form-control" id="education" name='education'>
                  <option disabled selected>Select Skills</option>
                  <option>Student</option>
                  <option>Bachelor's Degree</option>
                  <option>Master's Degree</option>
                  <option>Others</option>
                </select>
              </div>
            </div>
          </div>
        </form>

        {
          (searchField?.location || searchField?.education || searchField?.expertise) &&
          <button onClick={() => window.location.reload()} className="btn btn-bluish px-3 d-block mx-auto my-5">Clear Filter <i className="fas fa-search pl-3"></i></button>
        }
      </div>
    </div>
  );
}

export default SearchForm;
