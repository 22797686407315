import React, { useContext } from "react";
import "../../Styles/InterviewSection.css";
import interviewImg from "../../assests/images/new/Group 431.png";
import { Link } from "react-router-dom";

function InterviewSection() {
  // const { userCredentials,setIsCompany, } = useContext(UserContext)
  // const navigate = useNavigate()

  // const handleMarketPlace = () => {
  //     if (userCredentials?.email && userCredentials?.isCompany) {
  //         navigate('/dashboard/find-candidate')
  //     }
  //     else {
  //         setIsCompany(true)
  //         navigate('/sign-up')
  //     }
  // }

  return (
    <>
      <div className="interview-section">
        <div className="container py-md-4 py-0">
          <div className="row g-0 align-items-center   py-0">
            <div className="col-lg-6 col-md-7 order-1 order-md-0">
              <div className="interviewContent">
                <h3 className="interview-title-one my-4">
                  LinkIntern allows you to:
                </h3>
                <ul className="intern-description-two my-4 ml-3">
                 <li className="bullet-list">Instantly book and schedule multiple interviews with candidates</li> 
                 <li className="bullet-list">Assess multiple candidates simultaneously </li> 
                 <li className="bullet-list">Check the candidates’ expertise and availability</li>
                 <li className="bullet-list">Hire the perfect match</li>
                </ul>

                <Link to="/dashboard/find-candidate" className="d-none d-md-block">
                  <h5 className="candidate-call py-3 my-0 main-button">
                  Call for an interview, one click away
                  </h5>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 order-0 order-md-1 text-center">
              <img src={interviewImg} alt="" className="w-100 d-block mx-auto linkIntern-img" />

              <Link to="/dashboard/find-candidate" className="d-md-none">
                  <h5 className="candidate-call py-3 my-4">
                  Call for an interview, one click away
                  </h5>
                </Link>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InterviewSection;
