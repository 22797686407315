/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Common/Navbar";
import CompanyCard from "./CompanyCard";
import CreateOffer from "./CreateOffer";
import InternCard from "./InternCard";

function SuperAdmin() {
  const [type, setType] = useState("Intern");
  const [allIntern, setAllIntern] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [showData, setShowData] = useState([]);

  useEffect(() => {
    fetch("https://linkintern-backend.herokuapp.com/get-user")
      .then((res) => res.json())
      .then((data) => {
        setAllIntern(data.filter((info) => !info.isCompany));
        setAllCompany(data.filter((info) => info.isCompany));
      });
  }, []);

  useEffect(() => {
    if (type === "Intern") {
      setShowData(allIntern);
    }
    else {
      setShowData(allCompany);
    }
  }, [type, allCompany, allIntern]);

  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center pt-5">
          <div className="col-lg-3 col-6">
            <a
              onClick={() => setType("Company")}
              className={`d-block pointer text-center btn-hover super-admin-button h4 ${
                type === "Company" ? "" : "text-white"
              }`}
            >
              Company
            </a>
          </div>
          <div className="col-lg-3 col-6">
            <a
              onClick={() => setType("Intern")}
              className={`d-block pointer text-center btn-hover super-admin-button h4 ${
                type === "Intern" ? "" : "text-white"
              }`}
            >
              Intern
            </a>
          </div>

          <div className="col-lg-3 col-6">
            <a
              onClick={() => setType("offer")}
              className={`d-block pointer text-center btn-hover super-admin-button h4 ${
                type === "offer" ? "" : "text-white"
              }`}
            >
              Create Offer
            </a>
          </div>

        </div>

        <div className="row align-items-center justify-content-center my-5">
          {type === "Intern" && showData?.length
            ? showData.map((data) => (
                <div className="col-lg-4 col-md-6 my-3">
                  <InternCard data={data} />
                </div>
              ))
            : type === "Company" ? showData.map((data) => (
                <div className="col-lg-4 col-md-6 my-3">
                  <CompanyCard data={data} />
                </div>
            )) : <div className="col-lg-9 col-md-12 my-3">
                <CreateOffer/>
            </div> }
            
         
        </div>
      </div>
    </div>
  );
}

export default SuperAdmin;
