import React, { useContext } from "react";
import "../../Styles/CategorySidebar.css";
import { UserContext } from "../../context/AppContext"


const CategorySidebar = () => {
  const { searchFields, setSearchFields } = useContext(UserContext)

  const handleSearchList = e => {
    if (e.target.checked) {
      setSearchFields([...searchFields, e.target.value])
    }
    else {
      setSearchFields(searchFields.filter(item => item !== e.target.value))
    }
  }
  return (
    <>
      <div className="category-sidebar">
        <h3 className="category-list pb-2">Category</h3>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled1"
            onChange={(e) => handleSearchList(e)}
            value="Finance Management & Ops"
          />
          <label className="custom-control-label" for="customCheckDisabled1">
            Finance
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled2"
            onChange={(e) => handleSearchList(e)}
            value="Sales & Marketing"
          />
          <label className="custom-control-label" for="customCheckDisabled2">
            Sales & Marketing
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled3"
            onChange={(e) => handleSearchList(e)}
            value="Product & Engineering"
          />
          <label className="custom-control-label" for="customCheckDisabled3">
            Product & Engineering
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled4"
            onChange={(e) => handleSearchList(e)}
            value="Service"
          />
          <label className="custom-control-label" for="customCheckDisabled4">
            Service
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled5"
            onChange={(e) => handleSearchList(e)}
            value="Arts & Fashion"
          />
          <label className="custom-control-label" for="customCheckDisabled5">
            Art & Fashion
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled6"
            onChange={(e) => handleSearchList(e)}
            value="Health & Science"
          />
          <label className="custom-control-label" for="customCheckDisabled6">
            Health & Science
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled7"
            onChange={(e) => handleSearchList(e)}
            value="Education"
          />
          <label className="custom-control-label" for="customCheckDisabled7">
            Education
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled8"
            onChange={(e) => handleSearchList(e)}
            value="Software Development"
          />
          <label className="custom-control-label" for="customCheckDisabled8">
            IT Industry
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled9"
            onChange={(e) => handleSearchList(e)}
            value="Law & Journalism"
          />
          <label className="custom-control-label" for="customCheckDisabled9">
            Law & Journalism
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled10"
            onChange={(e) => handleSearchList(e)}
            value="Networking Opportunities"

          />
          <label className="custom-control-label" for="customCheckDisabled10">
            Networking
          </label>
        </div>
      </div>

      <div className="experiance-sidebar">
        <h3 className="category-list pb-2">Experiance Level</h3>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled11"
            onChange={(e) => handleSearchList(e)}
            value="Entry Level"
          />
          <label className="custom-control-label" for="customCheckDisabled11">
            Entry Level
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled12"
            onChange={(e) => handleSearchList(e)}
            value="Intermediate"
          />
          <label className="custom-control-label" for="customCheckDisabled12">
            Intermediate
          </label>
        </div>
        <div className="custom-control custom-checkbox pb-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheckDisabled13"
            onChange={(e) => handleSearchList(e)}
            value="Expert"
          />
          <label className="custom-control-label" for="customCheckDisabled13">
            Expert
          </label>
        </div>
      </div>
    </>
  );
};

export default CategorySidebar;
