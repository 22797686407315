/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import { UserContext } from "../../context/AppContext";
import thumb from '../../assests/images/dashboard/thub.png';

const MarketplaceCard = ({ service }) => {
  const { userCredentials } = useContext(UserContext)
  const { _id, offerTitle, companyName, siteLink } = service

  const handleDelete = id => {
    fetch(`https://linkintern-backend.herokuapp.com/deleteOffer/${id}`, {
      method: 'DELETE',
    })
      .then(res => res.json())
      .then(data => {
        if (data) {
          window.location.reload()
        }
      })
  }

  return (
    <>
      <div class="card marketplace-card">
        <img
          src={thumb}
          class="card-img-top"
          alt="..."
          className="w-100"
        />
        <div class="card-body d-flex flex-column justify-content-between">
          <div>
            <h3 class="card-title card-title-one text-dark">
              {companyName}
            </h3>
            <h5 className="mb-3">
              {offerTitle}
            </h5>
          </div>
          <div>
            <input type="text" placeholder="apply cupon code" className="form-control" />
            <div className="d-flex flex-column justify-content-between align-items-center">
              <a href={siteLink} target="_blank" class="w-100 btn btn-outline-primary mt-3">
                Get The Service
              </a>
              {
                userCredentials?.email === 'super.admin@gmail.com' &&
                <a onClick={() => handleDelete(_id)} class="w-100 btn btn-primary mt-3">
                  Delete
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketplaceCard;
