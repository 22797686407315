import React, { useState } from "react";

function ContactForm() {
  return (
    <>
      <div className="contact-form">
        <p className="contact-msg">Leave us a message</p>
        <form className="py-3">
          <div className="form-group">
            <input
              type="text"
              placeholder="Full Name"
              className="form-control py-4"
              name="name"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              placeholder="Email Address"
              className="form-control py-4"
              name="email"
              required
            />
          </div>

          <div className="form-group message">
            <textarea
              rows="6"
              cols="10"
              placeholder="Your Message"
              className="form-control"
              name="message"
              required
            />
          </div>

          <div className="form-group ">
            <button type="submit" className="login-button d-block my-5 w-100">
              Send
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
