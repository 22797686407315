import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UserContext } from '../../context/AppContext'
import DashboardTopbar from './DashboardTopbar'
import logo from '../../assests/logo/logoTranparent.png'

function DashboardTopNav() {
    const { userCredentials } = useContext(UserContext)
    const [activeLink, setActiveLink] = useState('Dashboard')
    const location = useLocation()
    const screenWidth = window.innerWidth;

    useEffect(() => {
        setActiveLink((location.pathname === '/dashboard/post-job' || location.pathname === '/dashboard/subscription' || location.pathname === '/dashboard/business-details' || location.pathname === '/dashboard') ? 'Dashboard' : location.pathname === '/dashboard/inbox' ? 'Messages' : location.pathname === '/dashboard/jobs' ? 'Jobs' : location.pathname === '/dashboard/find-candidate' ? 'Interview' : location.pathname === '/dashboard/training' ? 'Join Our Training' : 'Dashboard')
    }, [location?.pathname])


    return (
        <div className='dashboard-top-nav'>
            <div className="mx-md-5 mx-2 d-block mx-auto">
                <nav className="navbar navbar-expand-xl">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="logo" alt='logo' />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon">
                            <i className="fas fa-bars toggler-icon"></i>
                        </span>
                    </button>

                    <div className="collapse navbar-collapse my-5 my-lg-0" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {userCredentials.email === 'super.admin@gmail.com' ?
                                <li className="nav-item">
                                    <Link className={`nav-link ${activeLink === "Super Admin" ? 'text-blue' : 'style-two'}`} to="/super-admin">
                                        Super Admin
                                    </Link>
                                </li>
                                : <></>
                            }
                            <li className="nav-item">
                                <Link className={`nav-link ${activeLink === "Dashboard" && screenWidth > 1199 ? 'style-one' : activeLink === "Dashboard" ? 'text-blue' : "style-two"}`} to="/dashboard">
                                    Dashboard
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${activeLink === "Messages" && screenWidth > 1199 ? 'style-one' : 'style-two'}`} to="/dashboard/inbox">
                                    Messages
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className={`nav-link ${activeLink === "Join Our Training" ? 'text-blue' : 'text-dark'}`} to="/dashboard/training">
                                    Join Our Training
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className={`nav-link ${activeLink === "Jobs" && screenWidth > 1199 ? 'text-blue' : 'style-two'}`} to="/job">
                                    Jobs
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${activeLink === "Marketplace" && screenWidth > 1199 ? 'text-blue' : 'style-two'}`} to="/marketplace">
                                    Marketplace
                                </Link>
                            </li>
                            {
                                userCredentials?.hasOwnProperty('isCompany') &&
                                <li className="nav-item ">
                                    <Link className={`nav-link ${activeLink === "Interview" && screenWidth > 1199 ? 'style-one' : 'style-two'}`} to="/dashboard/find-candidate">
                                        Interview
                                    </Link>
                                </li>
                            }

                        </ul>

                        <div className='ml-auto d-block'>
                            <DashboardTopbar />
                        </div>

                    </div>
                </nav>
            </div>
        </div>
    )
}

export default DashboardTopNav
