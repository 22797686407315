/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/AppContext";
import "../../Styles/Resume.css";
import Modal from "./Modal";
import PieChartContainer from "./PieChartContainer";
import { useLocation } from "react-router-dom";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFDownloadLink,
    Link,
    Image,
} from "@react-pdf/renderer";
import { toPng } from "html-to-image";

function Resume() {
    const screenWidth = window.innerWidth;
    const { resumeData, resumeSocialLink } = useContext(UserContext);
    const ref = createRef();
    const location = useLocation();
    const [showDownload, setShowDownload] = useState(true);
    const [imgBase64, setImgBase64] = useState("");
    const isLocation = location.pathname === "/dashboard/create-portfolio";

    const styles = StyleSheet.create({
        resumeBG: {
            width: "75%",
            display: "block",
            margin: "auto",
            padding: 30,
        },
        flexView: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 5,
        },
        borderBottom: {
            borderBottom: "1px solid black",
        },
        h5: {
            marginBottom: 10,
            fontWeight: 500,
            fontSize: 14,
        },
        h1: {
            marginVertical: 15,
            fontWeight: 500,
            fontSize: 55,
        },
        resumeLInk: {
            cursor: "pointer",
            fontSize: 16,
            fontWeight: 500,
            color: "#292a25",
            paddingBottom: 15,
            textDecoration: "none",
        },
        linkBox: {
            width: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            alignSelf: 'flex-start'
        },
        resumeContent: {
            width: "70%",
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: 400,
            color: "#292a25",
            wordWrap: "break-word",
        },
    });

    useEffect(() => {
        setTimeout(() => {
            const chart = document.getElementById("chart1")
            if (chart !== null) {
                toPng(document.getElementById("chart1")).then(function (dataUrl) {
                    setImgBase64(dataUrl);
                    setShowDownload(false);
                });
            }
            else {
                setShowDownload(false);
            }
        }, 1000)
    }, [resumeData?.skills?.length]);


    return (
        <div className={`portfolio-card  ${isLocation ? "p-md-4 p-2 my-5" : ""}`}>
            <div className="resume-bg p-md-5 p-2" ref={ref}>
                <div className="d-flex justify-content-between">
                    <h5>Resume</h5>
                    <h5>{resumeData?.address || "Africa"}</h5>
                </div>
                <hr className="border-secondary" />

                <h1 className="resume-title">
                    {resumeData?.fullName || "Jonatha Smith"}
                </h1>

                <div className="row px-2">
                    <div className="col-md-8 px-0">
                        <p className="resume-content my-4">
                            {resumeData?.contentLetter ||
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"}
                        </p>
                    </div>
                    <div
                        className={`col-md-4 px-0 ${screenWidth > 769 ? "text-right" : "text-left"
                            }`}
                    >
                        {Object.keys(resumeSocialLink)?.map((key) => (
                            <a
                                href={resumeSocialLink[key]}
                                target="_blank"
                                className="my-4 d-block social-resume-link"
                            >
                                {key}
                            </a>
                        ))}
                    </div>
                </div>

                <div className="row align-items-center justify-content-center">
                    <div className="col-md-7 col-10">
                        {resumeData?.skills?.length === 3 ? (
                            <PieChartContainer skills={resumeData?.skills} />
                        ) : (
                            <></>
                        )}
                    </div>
                    {resumeData?.skills?.length === 3 ? (
                        <div className="col-md-5 col-6">
                            <ul>
                                <li id="skill1" className="font-weight-bold">
                                    {resumeData?.skills[0]?.name}
                                </li>
                                <li id="skill2" className="font-weight-bold">
                                    {resumeData?.skills[1]?.name}
                                </li>
                                <li id="skill3" className="font-weight-bold">
                                    {resumeData?.skills[2]?.name}
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <Modal />

                <div>
                    <h3 className="">Experience</h3>
                    <p className={`${screenWidth > 769 ? "w-50" : "w-100"}`}>
                        <h5 className="pt-2">
                            Position:{" "}
                            <span className="font-weight-normal">
                                {resumeData?.position}
                            </span>
                        </h5>
                    </p>
                    <h5 className="">
                        Company Name:{" "}
                        <span className="font-weight-normal">
                            {resumeData?.companyInfo}
                        </span>
                    </h5>
                    <h5 className="">
                        year:{" "}
                        <span className="font-weight-normal">
                            {resumeData?.workingYear}
                        </span>
                    </h5>
                </div>

                {resumeData?.position2 &&
                    <div className="pt-3">
                        <h3 className="">Experience</h3>
                        <p className={`${screenWidth > 769 ? "w-50" : "w-100"}`}>
                            <h5 className="pt-2">
                                Position:{" "}
                                <span className="font-weight-normal">
                                    {resumeData?.position2}
                                </span>
                            </h5>
                        </p>
                        <h5 className="">
                            Company Name:{" "}
                            <span className="font-weight-normal">
                                {resumeData?.companyInfo2}
                            </span>
                        </h5>
                        <h5 className="">
                            year:{" "}
                            <span className="font-weight-normal">
                                {resumeData?.workingYear2}
                            </span>
                        </h5>
                    </div>
                }

                <div>
                    <h3 className="resume-contact">Contact</h3>
                    <p className={`${screenWidth > 769 ? "w-50" : "w-100"}`}>
                        I’m currently looking for an internship at an agency for the summer,
                        so feel free to drop me
                    </p>
                    <p>Email: {resumeData?.email || "test@gmail.com"} </p>
                    <p>Tel: {resumeData?.tel || "+47 xxxxxx"}</p>
                </div>
            </div>

            <div className="py-5 resume-bg">
                {!showDownload ? (
                    <PDFDownloadLink
                        style={{
                            color: "white",
                            backgroundColor: "#6AB6F0",
                            padding: 20,
                            borderRadius: 8,
                            display: "block",
                            width: "210px",
                            textAlign: "center",
                            margin: "auto",
                        }}
                        document={
                            <>
                                <Document>
                                    <Page size="A4" style={styles.resumeBG}>
                                        <View style={[styles.flexView, styles.borderBottom]}>
                                            <Text style={styles.h5}>Resume</Text>
                                            <Text style={styles.h5}>{resumeData?.address}</Text>
                                        </View>

                                        <Text style={[styles.h1, { color: "#292a25" }]}>
                                            {resumeData?.fullName}
                                        </Text>

                                        <View style={styles.flexView}>
                                            <Text style={styles.resumeContent}>
                                                {resumeData?.contentLetter}
                                            </Text>
                                            <View style={styles.linkBox}>
                                                {Object.keys(resumeSocialLink)?.map((key) => (
                                                    <Link style={styles.resumeLInk} src={resumeSocialLink[key]}>
                                                        {key}
                                                    </Link>
                                                ))}
                                            </View>
                                        </View>

                                        <View style={styles.flexView}>
                                            {imgBase64 ?
                                                <View style={{ width: "45%" }}>
                                                    <Image
                                                        style={{ width: 350 }}
                                                        source={{ uri: imgBase64 }}
                                                    />
                                                </View>
                                                :
                                                <></>
                                            }

                                            {resumeData?.skills?.length ? (

                                                <View
                                                    style={{
                                                        width: "40%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start",
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            fontSize: 22,
                                                            fontWeight: 500,
                                                            paddingBottom: 5,
                                                        }}
                                                    >
                                                        Skills
                                                    </Text>
                                                    {resumeData?.skills[0]?.name ?
                                                        <Text
                                                            style={{
                                                                paddingVertical: 10,
                                                                fontSize: 14,
                                                                color: "#6AB6F0",
                                                            }}
                                                        >
                                                            {resumeData?.skills[0]?.name}
                                                        </Text>

                                                        :
                                                        <></>
                                                    }
                                                    {resumeData?.skills[1]?.name ?
                                                        <Text
                                                            style={{
                                                                paddingVertical: 10,
                                                                fontSize: 14,
                                                                color: "#6AB6F0",
                                                            }}
                                                        >
                                                            {resumeData?.skills[1]?.name}
                                                        </Text>
                                                        :
                                                        <></>
                                                    }
                                                    {resumeData?.skills[2]?.name ?
                                                        <Text
                                                            style={{
                                                                paddingVertical: 10,
                                                                fontSize: 14,
                                                                color: "#6AB6F0",
                                                            }}
                                                        >
                                                            {resumeData?.skills[2]?.name}
                                                        </Text>
                                                        :
                                                        <></>
                                                    }
                                                </View>
                                            ) : (
                                                <></>
                                            )}
                                        </View>

                                        <View style={{ paddingTop: 15 }}>
                                            <Text
                                                style={[
                                                    { fontSize: 18, fontWeight: 500, paddingBottom: 8 },
                                                    styles.borderBottom,
                                                ]}
                                            >
                                                Portfolio One
                                            </Text>
                                            {resumeData?.hasOwnProperty("projects") ? (
                                                <View style={{ paddingTop: 8 }}>
                                                    <Text
                                                        style={{
                                                            fontSize: 24,
                                                            fontWeight: 500,
                                                            paddingBottom: 15,
                                                        }}
                                                    >
                                                        {resumeData?.projects[0]?.projectOneName}
                                                    </Text>
                                                    <Link
                                                        src={resumeData?.projects[0]?.projectOneLink}
                                                        style={[
                                                            styles.resumeLInk,
                                                            {
                                                                fontSize: 14,
                                                                color: "#6AB6F0",
                                                                paddingBottom: 10,
                                                            },
                                                        ]}
                                                    >
                                                        Live Link
                                                    </Link>
                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            paddingBottom: 10,
                                                        }}
                                                    >
                                                        Project Details:
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            paddingBottom: 10,
                                                            lineHeight: 1.5,
                                                        }}
                                                    >
                                                        {resumeData?.projects[0]?.projectOneDetails}
                                                    </Text>
                                                </View>
                                            ) : <></>}
                                        </View>

                                        {resumeData?.projects[1]?.projectTwoName ? (
                                            <View style={{ paddingTop: 15 }}>
                                                <Text
                                                    style={[
                                                        { fontSize: 18, fontWeight: 500, paddingBottom: 8 },
                                                        styles.borderBottom,
                                                    ]}
                                                >
                                                    Portfolio Two
                                                </Text>
                                                {resumeData?.hasOwnProperty("projects") && (
                                                    <View style={{ paddingTop: 8 }}>
                                                        <Text
                                                            style={{
                                                                fontSize: 24,
                                                                fontWeight: 500,
                                                                paddingBottom: 15,
                                                            }}
                                                        >
                                                            {resumeData?.projects[1]?.projectTwoName}
                                                        </Text>
                                                        <Link
                                                            src={resumeData?.projects[1]?.projectTwoLink}
                                                            style={[
                                                                styles.resumeLInk,
                                                                {
                                                                    fontSize: 14,
                                                                    color: "#6AB6F0",
                                                                    paddingBottom: 10,
                                                                },
                                                            ]}
                                                        >
                                                            Live Link
                                                        </Link>
                                                        <Text
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: 500,
                                                                paddingBottom: 10,
                                                            }}
                                                        >
                                                            Project Details:
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 10,
                                                                lineHeight: 1.5,
                                                            }}
                                                        >
                                                            {resumeData?.projects[1]?.projectTwoDetails}
                                                        </Text>
                                                    </View>
                                                )}
                                            </View>
                                        ) : (
                                            <></>
                                        )}

                                        <View style={{ paddingTop: 15 }}>
                                            <Text
                                                style={[
                                                    { fontSize: 18, fontWeight: 500, paddingBottom: 8 },
                                                    styles.borderBottom,
                                                ]}
                                            >
                                                Education
                                            </Text>
                                            {resumeData?.hasOwnProperty("projects") ? (
                                                <View style={{ paddingTop: 8 }}>
                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            paddingBottom: 8,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Institute: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 8,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.college}
                                                        </Text>
                                                    </Text>

                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            paddingBottom: 8,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Education Level: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 8,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.education}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            ) : <></>}
                                        </View>

                                        <View style={{ paddingTop: 8 }}>
                                            <Text
                                                style={{
                                                    fontSize: 22,
                                                    fontWeight: 500,
                                                    paddingBottom: 5,
                                                }}
                                            >
                                                Experience
                                            </Text>
                                            {resumeData?.hasOwnProperty("position") ? (
                                                <View style={{ paddingTop: 5 }}>
                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Position: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 5,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.position}
                                                        </Text>
                                                    </Text>

                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Compnay Name: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 5,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.companyInfo}
                                                        </Text>
                                                    </Text>

                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Year: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 5,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.workingYear}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            ) : <></>}
                                        </View>

                                        <View style={{ paddingTop: 8 }}>
                                            {resumeData?.hasOwnProperty("position2") ? (
                                                <View style={{ paddingTop: 5 }}>
                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Position: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 5,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.position2}
                                                        </Text>
                                                    </Text>

                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Compnay Name: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 5,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.companyInfo2}
                                                        </Text>
                                                    </Text>

                                                    <Text
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            display: "inline",
                                                            width: "50%",
                                                        }}
                                                    >
                                                        Year: &nbsp;
                                                        <Text
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: 400,
                                                                paddingBottom: 5,
                                                                lineHeight: 1.5,
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {resumeData?.workingYear2}
                                                        </Text>
                                                    </Text>
                                                </View>
                                            ) : <></>}
                                        </View>


                                        <View style={{ paddingTop: 8 }}>
                                            <Text
                                                style={{
                                                    fontSize: 22,
                                                    fontWeight: 500,
                                                    paddingBottom: 5,
                                                }}
                                            >
                                                Contact
                                            </Text>
                                            <View style={{ paddingTop: 5 }}>
                                                <Text
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        paddingBottom: 8,
                                                        lineHeight: 1.5,
                                                    }}
                                                >
                                                    I’m currently looking for an internship at an agency
                                                    for the summer, so feel free to drop me
                                                </Text>

                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                        paddingBottom: 5,
                                                        display: "inline",
                                                        width: "50%",
                                                    }}
                                                >
                                                    Email: &nbsp;
                                                    <Text
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            lineHeight: 1.5,
                                                            width: "50%",
                                                        }}
                                                    >
                                                        {resumeData?.email}
                                                    </Text>
                                                </Text>

                                                <Text
                                                    style={{
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                        paddingBottom: 5,
                                                        display: "inline",
                                                        width: "50%",
                                                    }}
                                                >
                                                    Tel: &nbsp;
                                                    <Text
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            paddingBottom: 5,
                                                            lineHeight: 1.5,
                                                            width: "50%",
                                                        }}
                                                    >
                                                        {resumeData?.tel}
                                                    </Text>
                                                </Text>
                                            </View>
                                        </View>
                                    </Page>
                                </Document>
                            </>
                        }
                        fileName="Resume.pdf"
                    >
                        {({ blob, url, loading, error }) => {
                            if (error) {
                                console.log(error);
                                return
                            }
                            return loading ? "Loading document..." : "Download now!"
                        }
                        }
                    </PDFDownloadLink>
                ) : (
                    <button className="btn btn-secondary px-4 py-3 d-block mx-auto">
                        Generating Download...
                    </button>
                )}
            </div>
        </div>
    );
}

export default Resume;
