/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Banner.css";
import bannerImg from '../../assests/images/new/satisfied-lovely-woman-holds-modern-cell-phone 1.png'
import bannerTwo from '../../assests/images/new/banner_img_main.png';
import banner_img_paypal from '../../assests/images/new/banner_img_paypal.png';
import banner_img_icon from '../../assests/images/new/banner_img_icon.png';
import banner_img_google from '../../assests/images/new/banner_img_google.png';
import banner_img_slack from '../../assests/images/new/banner_img_slack.png';
import maliha from '../../assests/images/new/maliha.png';
import adam from '../../assests/images/new/adam.png';
import { UserContext } from "../../context/AppContext";

function Banner() {
  const { userCredentials, setIsCompany, isCompany } = useContext(UserContext);
  const navigate = useNavigate();

  const handleCreatePortfolio = () => {
    if (userCredentials?.email && !isCompany) {
      navigate("/dashboard/portfolio");
    } else {
      setIsCompany(false);
      navigate("/sign-up");
    }
  };

  return (
    <div className="banner-section">
      <div className="container pt-3">
        <div className="row align-items-center">
          <div className="col-md-6 ">
            <div className="banner-content">
                <h3 className="banner-title">
                  The one platform </h3>
                  <h5 className="banner-subtitle"> that connects ambitious tech graduates and future-driven companies</h5>
                <p className="banner-description mt-2 mb-3">LinkIntern connects top talent and future-driven companies in a few clicks</p>
              <Link to="/login-as"> <button className="explore-button">Explore Now</button> </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="heroBannerImageBox">
              
              <img src={banner_img_icon} alt="banner_img_icon" className="banner_img_icon  d-block mx-auto" />
              <img src={banner_img_google} alt="banner_img_google" className="banner_img_google  d-block mx-auto" />
              <img src={bannerTwo} alt="banner_img_main" className="banner_img_main  d-block mx-auto" />
              <img src={banner_img_slack} alt="banner_img_slack" className=" banner_img_slack d-block mx-auto" />
              <img src={banner_img_paypal} alt="banner_img_paypal" className=" banner_img_paypal d-block mx-auto" />

              <div className="heroBannerIntern maliha">
                <div className="imag">
                  <img src={maliha} alt="maliha" className="   d-block mx-auto" />
                  <div> 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.8325 4.84315L8.6768 4.38452L7.26611 1.52462C7.22758 1.44631 7.16419 1.38293 7.08589 1.3444C6.88951 1.24745 6.65088 1.32824 6.55269 1.52462L5.142 4.38452L1.98628 4.84315C1.89928 4.85558 1.81973 4.8966 1.75883 4.95874C1.6852 5.03442 1.64463 5.13623 1.64603 5.2418C1.64743 5.34738 1.69068 5.44808 1.76629 5.52177L4.04949 7.7478L3.51007 10.8911C3.49743 10.9642 3.50552 11.0394 3.53343 11.1082C3.56135 11.1769 3.60797 11.2365 3.66801 11.2801C3.72805 11.3237 3.79911 11.3496 3.87312 11.3549C3.94714 11.3602 4.02116 11.3446 4.08678 11.3099L6.9094 9.82593L9.73202 11.3099C9.80908 11.351 9.89857 11.3646 9.98433 11.3497C10.2006 11.3124 10.346 11.1074 10.3087 10.8911L9.76931 7.7478L12.0525 5.52177C12.1147 5.46087 12.1557 5.38133 12.1681 5.29432C12.2017 5.07682 12.05 4.87547 11.8325 4.84315Z" fill="#F5A700"/>
                    </svg> 
                    <strong>4.8</strong>
                  </div>
                </div>
                <h5>Maliha</h5>
                <span>Ux Designer</span>
              </div>


              <div className="heroBannerIntern adam">
                <div className="imag">
                  <img src={adam} alt="adam" className="   d-block mx-auto" />
                  <div> 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.8325 4.84315L8.6768 4.38452L7.26611 1.52462C7.22758 1.44631 7.16419 1.38293 7.08589 1.3444C6.88951 1.24745 6.65088 1.32824 6.55269 1.52462L5.142 4.38452L1.98628 4.84315C1.89928 4.85558 1.81973 4.8966 1.75883 4.95874C1.6852 5.03442 1.64463 5.13623 1.64603 5.2418C1.64743 5.34738 1.69068 5.44808 1.76629 5.52177L4.04949 7.7478L3.51007 10.8911C3.49743 10.9642 3.50552 11.0394 3.53343 11.1082C3.56135 11.1769 3.60797 11.2365 3.66801 11.2801C3.72805 11.3237 3.79911 11.3496 3.87312 11.3549C3.94714 11.3602 4.02116 11.3446 4.08678 11.3099L6.9094 9.82593L9.73202 11.3099C9.80908 11.351 9.89857 11.3646 9.98433 11.3497C10.2006 11.3124 10.346 11.1074 10.3087 10.8911L9.76931 7.7478L12.0525 5.52177C12.1147 5.46087 12.1557 5.38133 12.1681 5.29432C12.2017 5.07682 12.05 4.87547 11.8325 4.84315Z" fill="#F5A700"/>
                    </svg> 
                    <strong>4.8</strong>
                  </div>
                </div>
                <h5>Adam</h5>
                <span>Project Manager</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
