import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { UserContext } from '../../context/AppContext'
import Stepper from 'react-stepper-horizontal'

function CustomStepper() {
    const { stepperCount } = useContext(UserContext)
    // const location = useLocation()
    // const businessActive = businessDetails?.companyName ? 'bg-dark-stepper text-white' : location.pathname === '/dashboard/business-details' ? 'bg-light-stepper text-dark' : 'bg-white text-dark'
    // const subscriptionActive = subscription?.title ? 'bg-dark-stepper text-white' : location.pathname === '/dashboard/subscription' ? 'bg-light-stepper text-dark' : 'bg-white text-dark'
    // const jobPostActive = location.pathname === '/dashboard/post-job' ? 'bg-light-stepper text-dark' : 'bg-white text-dark'


    return (
        <div className='container stepper pt-5'>
            <div>
                <Stepper circleFontSize={18} defaultBorderColor='#1966EA' size={40} steps={[{ title: 'Business Details',href: '/dashboard/business-details' }, { title: 'Membership Select', href: '/dashboard/subscription' }, { title: 'Job Posting', href:'/dashboard/post-job' }]} activeStep={stepperCount} />
            </div>
            {/* <div className='row'>
                <div className='col-4 custom-stepper-type-1 bg-dark-stepper p-0'>
                    <Link to='/dashboard/business-details'>
                        <div className={`custom-stepper-type-1 d-flex border align-items-center ${businessActive} justify-content-center`}>
                            <p className='mb-0'>Business Details</p>
                        </div>
                    </Link>
                </div>
                <div className='col-4 custom-stepper-type-2 bg-dark-stepper p-0'>
                    <Link to='/dashboard/subscription'>
                        <div className={`custom-stepper-type-2 ${subscriptionActive} border d-flex align-items-center justify-content-center`}>
                            <p className='mb-0'>Membership Select</p>
                        </div>
                    </Link>
                </div>
                <div className='col-4 custom-stepper-type-3 p-0 bg-dark-stepper'>
                    <Link to='/dashboard/post-job'>
                        <div className={`custom-stepper-type-3 border ${jobPostActive} d-flex align-items-center justify-content-center`}>
                            <p className='mb-0'>Job Posting</p>
                        </div>
                    </Link>
                </div>
            </div> */}
        </div>
    )
}

export default CustomStepper
