/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from "react";
import "../../Styles/Modal.css";
import { UserContext } from "../../context/AppContext";

const Modal = () => {
  const { resumeData } = useContext(UserContext);

  return (
    <>
      <div className="modal-section">
        <ul>
          {/* Portfolio One */}
          <li>
            Portfolio1
            <hr className="border-secondary " />
            {
              resumeData?.hasOwnProperty("projects") &&
              <div className="content">
                <h4 className="text-dark">
                  {" "}
                  {resumeData?.projects[0]?.projectOneName}
                </h4>
                <h5 className="py-2">

                  <a
                    href={resumeData?.projects[0]?.projectOneLink}
                    target="_blank"
                  >
                    Live Link
                  </a>
                </h5>
                <h5 className="py-2">Project Details:</h5>
                <p className="project-description">{resumeData?.projects[0]?.projectOneDetails}</p>
              </div>
            }
          </li>

          {/* Portfolio Two */}
          <li>
            portfolio2
            <hr className="border-secondary " />
            {
              resumeData?.hasOwnProperty("projects") &&
              <div className="content">
                <h4 className="text-dark">
                  {" "}
                  {resumeData?.projects[1]?.projectTwoName}
                </h4>
                <h5 className="py-2">

                  <a
                    href={resumeData?.projects[1]?.projectTwoLink}
                    target="_blank"
                  >
                    Live Link
                  </a>
                </h5>
                <h5 className="py-2">Project Details:</h5>
                <p className="project-description">{resumeData?.projects[1]?.projectTwoDetails}</p>
              </div>
            }
          </li>

          {/* Education */}
          <li>
            Education
            <hr className="border-secondary " />
            <div className="content">
              <h5 className="py-2">
                Institute:{" "}
                <span className="font-weight-normal">
                  {resumeData?.college}
                </span>
              </h5>
              <h5 className="py-2">
                Education Level:{" "}
                <span className="font-weight-normal">
                  {resumeData?.education}
                </span>
              </h5>
              <h5 className="py-2">
                GPA:{" "}
                <span className="font-weight-normal">
                  {resumeData?.CGPA}
                </span>
              </h5>
              <h5 className="py-2">
                Passing Year:{" "}
                <span className="font-weight-normal">
                  {resumeData?.passingYear}
                </span>
              </h5>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Modal;