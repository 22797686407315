import React, { useContext, useEffect, useState } from "react";
import "../../Styles/Job.css";
import Navbar from "../../components/Common/Navbar";
import JobCard from "../../components/Job/JobCard";
import CategorySidebar from "../../components/Common/CategorySidebar";
import { UserContext } from "../../context/AppContext";
import { useLocation } from "react-router-dom";
import ReactPaginate from 'react-paginate';

const AllJob = () => {
  const [jobData, setJobData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const { allCompany, searchFields } = useContext(UserContext)
  const [isData, setIsData] = useState(true)
  const location = useLocation()

  const dateWiseDataSort = data => {
    return data.sort((a, b) => new Date(b.date) - new Date(a.date))
  }

  useEffect(() => {
    fetch('https://linkintern-backend.herokuapp.com/get-user')
      .then(res => res.json())
      .then(data => {
        if (data?.length) {
          const sortedData = dateWiseDataSort(data?.filter(info => info?.hasOwnProperty('isCompany'))?.filter(data => data?.hasOwnProperty('job'))?.map(data => [...data?.job])?.flat())
          setJobData(sortedData);
        }
      })
    setIsData(false);
  }, [allCompany]);


  const handleSearch = (e) => {
    if (e.target.value !== "") {
      setJobData(
        allCompany?.filter(data => data?.hasOwnProperty('job'))?.map(data => [...data?.job])?.flat().filter(data => {
          const fulNews = `${data?.jobTitle} ${data?.description} ${data?.companyName} ${data?.requirements} ${data?.experience} ${data?.salary} ${data?.industry}`;
          if (fulNews.toLowerCase().includes(e.target.value.toLowerCase())) {
            return data;
          }
        })
      )
    }
    else {
      setJobData(allCompany?.filter(data => data?.hasOwnProperty('job'))?.map(data => [...data?.job])?.flat())
    }
  }

  useEffect(() => {
    if (searchFields.length > 0) {
      setJobData(allCompany?.filter(data => data?.hasOwnProperty('job'))?.map(data => [...data?.job])?.flat().filter(data => searchFields.includes(data?.industry) || searchFields.includes(data?.experience)))
    }
    else {
      setJobData(allCompany?.filter(data => data?.hasOwnProperty('job'))?.map(data => [...data?.job])?.flat())
    }
  }, [allCompany, searchFields])

  useEffect(() => {
    const endOffset = itemOffset + 3;
    setCurrentItems(jobData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(jobData.length / 3));
  }, [itemOffset, jobData]);


  const handlePageClick = (event) => {
    const newOffset = (event.selected * 3) % jobData.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {
        location.pathname !== '/dashboard/jobs' &&
        <Navbar />
      }
      <div className="all-job-bg">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-lg-2 px-0 d-none d-lg-block">
              <CategorySidebar />
            </div>
            <div className="col-lg-10 col-md-12 pb-2 pl-lg-4">
              <div className="text-center mb-4">
                <div className="input-group w-100">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control py-4"
                    placeholder="Search Jobs"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn subscribe-button"
                      type="button"
                      id="button-addon2"
                    >
                      <i className="text-white fas fa-search"></i>
                    </button>
                  </div>
                </div>

              </div>
              <h1 className="feature-job">Featured Job</h1>
              {!isData ?
                currentItems.map(job => <JobCard jobs={job} />)
                :
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Uploading...</span>
                  </div>
                </div>
              }

              <ReactPaginate
                className="job-pagination"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />

              {/* <div className="text-right">
                {
                  post === 3 ? <button type="button" className="btn-blue d-none mr-4" >
                    Previous
                  </button> : <button type="button" className="btn-blue" onClick={handleClickPrevious}>
                    Previous
                  </button>
                }
                {
                  post > jobData.length ? <button
                    type="button"
                    className="btn-blue d-none px-4"
                    onClick={handleClickNext}
                  >
                    Next
                  </button> : <button
                    type="button"
                    className="btn-blue px-4"
                    onClick={handleClickNext}
                  >
                    Next
                  </button>
                }
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllJob;
