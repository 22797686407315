import React, { useContext, useState } from 'react';
import { UserContext } from '../../context/AppContext';
import JobModal from './JobModal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const JobCard = ({ jobs }) => {
    const dayToMonth = day => Math.floor(day / 30);
    const hoursToDay = hours => Math.floor(hours / 24);
    const minToHours = min => Math.floor(min / 60);
    const msToMin = (ms) => Math.ceil(ms / (1000 * 60));
    const getRemainingDays = (date) => msToMin(new Date().getTime() - new Date(date).getTime());

    const { jobTitle, description, position, id, company, date } = jobs;
    const [isRead, setIsRead] = useState(false);
    const { userCredentials } = useContext(UserContext)
    const min = getRemainingDays(date)
    const hours = minToHours(min)
    const day = hoursToDay(hours)
    const month = dayToMonth(day)



    const handleJobDelete = id => {
        fetch(`https://linkintern-backend.herokuapp.com/deleteJob/${id}`, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.reload()
                }
            })
    }

    const confirmDelete = (id) => {
        confirmAlert({
            title: 'Delete Job',
            message: 'Are you sure to delete this job?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleJobDelete(id),
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    return (
        <>
            <div className="my-3">
                <div className="job-card">
                    <div className="d-flex justify-content-between">
                        <h5 className="job-title">Hiring For {jobTitle}</h5>
                        <button className="btn just-now px-lg-3 py-lg-2 p-2 ">{min < 5 ? 'Just Now' : min < 60 ? `${min} min` : (min >= 60 && hours < 24) ? `${hours} h` : (hours >= 24 && day < 30) ? `${day} d` : `${month} month`}</button>
                    </div>
                    <h6 className='text-secondary font-weight-bold'>{company}</h6>
                    <p className="job-description py-2">
                        {description?.slice(0, 300)}
                        <p className="read-more" onClick={() => setIsRead(true)} data-toggle="modal" data-target="#exampleModalLong" >...Read More</p>
                        {

                            isRead ?
                                <JobModal jobs={jobs} setIsRead={setIsRead} />
                                : ''
                        }
                    </p>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="job-type"><span className="round">•</span> {position}</p>
                        {
                            userCredentials?.email === 'super.admin@gmail.com' ? <button onClick={() => confirmDelete(id)} className='btn btn-blue px-3 py-2'>Delete</button> : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobCard;