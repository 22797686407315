import React from "react";

const CompanyModal = ({ data, setIsViewDetails }) => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalLong"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title job-title" id="exampleModalLongTitle">
                {data?.businessDetails?.companyName}
              </h5>
            </div>

            <div className="modal-body">
              <p>{data?.businessDetails?.aboutCompany}</p>
              <pre className="modal-job-description">{data?.description}</pre>
              <h5 className="mb-4">Job Post</h5>
              {data?.job?.map((totalJob) => (
                <ul>
                  <li>
                    <a href="/job">
                      {" "}
                      <h6>Hiring For {totalJob.jobTitle}</h6>
                    </a>
                    <hr className="border-secondary" />
                  </li>
                </ul>
              ))}
              <h6 className="user-contact-info">
                Website:{" "}
                <a
                  href={data?.businessDetails?.companyUrl}
                  target="_blank"
                  className="font-weight-normal"
                >
                  {data?.businessDetails?.companyUrl}
                </a>
              </h6>
              <h6 className="user-contact-info">
                Email: <span className="font-weight-normal">{data?.email}</span>
              </h6>
              <p className="user-contact-info">
                {" "}
                {data?.subscription?.title.length && (
                  <h6>
                    Subscription:{" "}
                    <span className="font-weight-normal">
                      {data?.subscription?.title}
                    </span>
                  </h6>
                )}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setIsViewDetails(false)}
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyModal;
