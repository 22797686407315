import React, { useEffect } from 'react'
import ApexCharts from 'apexcharts'

function PieChartContainer({ skills }) {

    useEffect(() => {
        if (skills?.length === 3) {
            const options1 = {
                chart: {
                    height: 230,
                    type: "radialBar",
                },
                series: [parseInt(skills[0]?.value), parseInt(skills[1]?.value), parseInt(skills[2]?.value)],
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function () {
                                    return `${((parseInt(skills[0]?.value) + parseInt(skills[1]?.value) + parseInt(skills[2]?.value)) / 3).toFixed(2)}%`;
                                }
                            }
                        }
                    }
                },
                labels: [skills[0]?.name, skills[1]?.name, skills[2]?.name],
                colors: ['#6AB6F0', '#C13900', '#FFC727']

            };

            new ApexCharts(document.querySelector("#chart1"), options1).render();
        }
    }, [])

    return (
        <div id="chart1" className='pointer'>

        </div>
    )
}

export default PieChartContainer
