import React from 'react'
import '../Styles/NotFound.css';
import Navbar from '../components/Common/Navbar'

function NotFound() {
    return (
        <div>
            <Navbar />
            <h1 className='display-4 text-danger text-center py-5'>Sorry! No result Found</h1>
        </div>
    )
}

export default NotFound
