import React from 'react';
import '../../Styles/CandidateTwoProfile.css';
import youngBoy from '../../assests/images/newHome/c1.png'
import blockBg from '../../assests/images/newHome/candidate-card.png'
import { Link } from 'react-router-dom';

const CandidateTwoProfile = () => {
    return (
        <>
            <div className='block-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 col-md-12'>
                            <div>
                            <img src={youngBoy} alt="" className='w-90'  />
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12'>
                        <div className='block-one'></div>
                            <div className='block-two'>
                                    <h6>Explore New Life</h6>
                                    <h3>Build your personal  account profile</h3>
                                    <p className='block-details'>Create your portfolio and get discovered by top European employers. Let the job offers come to you and find internships or entry-level jobs. Your future is bright, and it starts here.</p>
                                    <Link to='/dashboard/portfolio' className='btn block-button'>Create Your Portfolio</Link>
                            </div>
                        
                    </div>
                </div>
            </div> 
            </div> 
        </>
    );
};

export default CandidateTwoProfile;