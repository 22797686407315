import React from "react";
import "../../Styles/Home.css";
import Navbar from "../../components/Common/Navbar";
import Banner from "../../components/Home/Banner";
// import PageCard from "../../components/Home/PageCard";
import CandidateSection from "../../components/Home/CandidateSection";
import CompanySection from "../../components/Home/CompanySection";
import InterviewSection from "../../components/Home/InterviewSection";
import FAQ from "../../components/Home/FAQ";
import Footer from "../../components/Common/Footer";
import Counter from "../../components/Home/Counter";
import SocialShareButton from "../../components/Common/SocialShareButton";
import AboutUs from "../../components/Home/AboutUs";
import OfferByCompany from "../../components/Home/OfferByCompany";
import FooterTwo from "../../components/Common/FooterTwo";
import CandidateTwoProfile from "../../components/HomeTwo/CandidateTwoProfile";
import AllJobTwo from "../../components/HomeTwo/AllJobTwo";
import JobCatagoryTwo from "../../components/HomeTwo/JobCatagoryTwo";
import HowWorkTwo from "../../components/HomeTwo/HowWorkTwo";
import ForEmployeeTwo from "../../components/HomeTwo/ForEmployeeTwo";
import HowWork from "../../components/HomeTwo/HowWork";

function Home() {

  return (
    <div  style={{position: 'relative'}}>
      <div className="header-bg pt-3">
      <Navbar />
      <Banner />
      </div>
      <hr className="banner-border" />
      <CandidateSection/>
      <CompanySection />
      <HowWork/>
      <JobCatagoryTwo />
      <ForEmployeeTwo/>
      <InterviewSection />
      <AllJobTwo/>
      <CandidateTwoProfile/>
      <FAQ />
        <FooterTwo/>
      <SocialShareButton />
    </div>
  );
}

export default Home;
