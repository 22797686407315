import React from "react";
import "../../Styles/FAQ.css";
import FaqQuestion from "./FaqQuestion";
import faqImg from "../../assests/images/new/faq 1.png";

function FAQ() {
  return (
    <>
      <div id="faq" className="faq-section">
        <div className="container ">
          <div className="row align-items-center py-5">
            <div className="col-md-6">
              <div className="faqs">
                  <h3 className="faq-title my-4">Frequently Asked Questions</h3>
                  <p className="faq-description pb-3">
                    
                    Here are frequently asked questions about LinkIntern, our consultation process, and how to get started!
                  </p>
                  {/* <FaqQuestion /> */}

                  <div>
                    <input
                      type="checkbox"
                      id="question1"
                      name="q"
                      class="questions"
                    />
                    <div class="plus">+</div>
                    <label for="question1" class="question">
                      Q. Can I Shortlist The Candidate?
                    </label>

                    <div class="answers">
                      <p>
                      Yes, companies can select multiple candidates and shortlist candidates that meet the company’s needs.
                      </p>
                    </div>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="question2"
                      name="q"
                      class="questions"
                    />
                    <div class="plus">+</div>
                    <label for="question2" class="question">
                      Q. Can I Take Video Interview?
                    </label>
                    <div class="answers">
                      <p>
                      Companies can contact candidates via email with further information.
                      </p>
                    </div>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="question3"
                      name="q"
                      class="questions"
                    />
                    <div class="plus">+</div>
                    <label for="question3" class="question">
                      Q. Do I Have To Subscribe?
                    </label>

                    <div class="answers">
                      <p>
                      Yes, to attract the most qualified, skilled talent for any entry-level position. LinkIntern’s early-career product suite is purpose-built to recruit college and graduate students with 0-5 years experience for internship.
                      </p>
                    </div>
                  </div>
              </div>
            </div>

            <div className="col-md-6">
              <img src={faqImg} alt="" className="w-100 d-block mx-auto sticky-top" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
