import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../../context/AppContext'

function DashboardButtons() {
    const { businessDetails, subscription, isCompany } = useContext(UserContext)
    const screenHeight = window.innerHeight;

    const btns = [{
        text: 'Post A Job',
        icon: 'far fa-file-alt',
        link: businessDetails?.companyName ? subscription?.title ? 'post-job' : 'subscription' : 'business-details'
    },
    {
        text: 'Join Our Training',
        icon: 'fas fa-users',
        link: 'training'
    },
    {
        text: 'Interview',
        icon: 'far fa-comments',
        link: 'find-candidate'
    },
    {
        text: 'Portfolio',
        icon: 'far fa-file-alt',
        link: 'portfolio'
    },
    {
        text: 'Give Offers',
        icon: 'fab fa-buffer',
        link: 'offer'
    },
    ]

    return (
        <div className={`container d-flex align-items-center justify-content-center ${screenHeight > 650 ? 'vh-83' : 'vh-115'}`}>
            <div className='row align-items-center justify-content-center'>

                {
                    isCompany &&
                    <Link to={btns[0].link} className='col-lg-5 col-md-5 col-sm-5 my-4'>
                        <button className="dashboard-button d-block mx-auto"> <span>{btns[0].text}</span> <i className={`ml-3 ${btns[0].icon}`}></i> </button>
                    </Link>

                }

                {
                    isCompany ?
                        <Link to={btns[1].link} className='col-lg-5 col-md-5 col-sm-5 my-4'>
                            <button className="dashboard-button d-block mx-auto"> <span>{btns[1].text}</span> <i className={`ml-3 ${btns[1].icon}`}></i> </button>
                        </Link>
                        :
                        <Link to={btns[3].link} className='col-lg-12 my-4'>
                            <button className="dashboard-button d-block mx-auto"> <span>{btns[3].text}</span> <i className={`ml-3 ${btns[3].icon}`}></i> </button>
                        </Link>
                }

                {
                    isCompany ?
                        <Link to={btns[2].link} className='col-lg-5 col-md-5 col-sm-5 my-4'>
                            <button className="dashboard-button d-block mx-auto"> <span>{btns[2].text}</span> <i className={`ml-3 ${btns[2].icon}`}></i> </button>
                        </Link>
                        :
                        <Link to={btns[1].link} className='col-lg-12 my-4'>
                            <button className="dashboard-button d-block mx-auto"> <span>{btns[1].text}</span> <i className={`ml-3 ${btns[1].icon}`}></i> </button>
                        </Link>
                }
                {
                    isCompany ?
                        <Link to={btns[4].link} className='col-lg-5 col-md-5 col-sm-5 my-4'>
                            <button className="dashboard-button d-block mx-auto"> <span>{btns[4].text}</span> <i className={`ml-3 ${btns[4].icon}`}></i> </button>
                        </Link> : <></>
                }

            </div>
        </div>
    )
}

export default DashboardButtons