import React, { useContext } from 'react';
import { UserContext } from '../../context/AppContext';

function UserGuard({ children, isShow }) {
    const { userCredentials: { email } } = useContext(UserContext);

    return <>
        {
            email ?
               !isShow && children
                : isShow ?
                    children
                    : <></>
        }
    </>;
}

export default UserGuard;
