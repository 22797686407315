import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
// import './assets/font/Axiforma-Black.ttf'; 

import BusinessDetails from "./components/Dashboard/BusinessDetails";
import DashboardButtons from "./components/Dashboard/DashboardButtons";
import JobPostForm from "./components/Dashboard/JobPostForm";
import JoinTraining from "./components/Dashboard/JoinTraining";
import SubscripitonPlan from "./components/Dashboard/SubscripitonPlan";
import About from "./pages/About/About";
import SelectSignUpPage from "./pages/Auth/SelectSignUpPage";
import SignInPage from "./pages/Auth/SignInPage";
import SignUpPage from "./pages/Auth/SignUpPage";
import Contact from "./pages/Contact/Contact";
import AllJob from './pages/Job/AllJob';
import Dashoboard from "./pages/Dashboard/Dashoboard";
import Home from './pages/Home/Home'
import CreatePortfolio from "./pages/Portfolio/CreatePortfolio";
import ViewResume from "./components/Resume/ViewResume";
import SuperAdmin from "./pages/SuperAdmin/SuperAdmin";
import FindCandidates from "./components/Dashboard/FindCandidates";
import PrivateRoute from "./pages/Auth/PrivateRoute";
import NotFound from "./pages/NotFound";
import Marketplace from "./pages/marketplace/Marketplace";
import Message from "./pages/message/Message";
import GiveOffers from "./components/Dashboard/GiveOffers";
import ResetPassword from "./pages/Auth/ResetPassword";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login-as" element={<SelectSignUpPage />} />
      <Route path="sign-in" element={<SignInPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="about-us" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="job" element={<AllJob />} />
      <Route path="marketplace" element={<Marketplace />} />

      <Route path="dashboard/*" element={
        <PrivateRoute>
          <Dashoboard />
        </PrivateRoute>
      } >
        <Route path="" element={
          <PrivateRoute>
            <DashboardButtons />
          </PrivateRoute>
        } />
        <Route path="portfolio" element={
          <PrivateRoute>
            <CreatePortfolio />
          </PrivateRoute>
        } />
        <Route path="training" element={
          <PrivateRoute>
            <JoinTraining />
          </PrivateRoute>
        } />
        <Route path="offer" element={
          <PrivateRoute>
            <GiveOffers />
          </PrivateRoute>
        } />
        <Route path="inbox" element={
          <PrivateRoute>
            <Message />
          </PrivateRoute>
        } />
        <Route path="business-details" element={
          <PrivateRoute>
            <BusinessDetails />
          </PrivateRoute>
        } />
        <Route path="subscription" element={
          <PrivateRoute>
            <SubscripitonPlan />
          </PrivateRoute>
        } />
        <Route path="post-job" element={
          <PrivateRoute>
            <JobPostForm />
          </PrivateRoute>
        } />
        <Route path="find-candidate" element={
          <PrivateRoute>
            <FindCandidates />
          </PrivateRoute>
        } />
      </Route>



      <Route path="candidate/:id" element={<ViewResume />} />
      <Route path="super-admin" element={
        <PrivateRoute>
          <SuperAdmin />
        </PrivateRoute>
      } />

      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
