/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import company from "../../assests/images/new/briefCase.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CompanyModal from "./CompanyModal";

function CompanyCard({ data }) {
  const [isViewDetails, setIsViewDetails] = useState(false);

  const handleDelete = (id) => {
    fetch(`https://linkintern-backend.herokuapp.com/deleteUser/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          window.location.reload();
        }
      });
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Company",
      message: "Are you sure to delete this company?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };


  return (
    <>
      <div className="company-user">
        <img
          src={data?.businessDetails?.logo || company}
          alt=""
          className="company-logo"
        />

        <h5 className="user-name" >
          <span className="font-weight-normal">{data?.companyName}</span>
        </h5>
        <div className="user-info">
          {data?.businessDetails?.companyName.length && (
            <>
              <p>
                <span className="font-weight-normal">
                  {data?.businessDetails?.aboutCompany?.slice(0, 100)}...
                </span>
              </p>
            </>
          )}
        </div>
        <h6 className="user-contact-info">
          Website:{" "}
          <a
            href={data?.businessDetails?.companyUrl}
            target="_blank"
            className="font-weight-normal"
          >
            {data?.businessDetails?.companyUrl}
          </a>
        </h6>
        <h6 className="user-contact-info">
          Email: <span className="font-weight-normal">{data?.email}</span>
        </h6>
        <p className="user-contact-info">
          {" "}
          {data?.subscription?.title.length && (
            <h6>
              Subscription:{" "}
              <span className="font-weight-normal">
                {data?.subscription?.title}
              </span>
            </h6>
          )}
        </p>

        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-hover btn-bluish"
            onClick={() => setIsViewDetails(true)}
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            View Details
          </button>
          {isViewDetails ? (
            <CompanyModal data={data} setIsViewDetails={setIsViewDetails} />
          ) : (
            ""
          )}

          <button
            onClick={() => confirmDelete(data?._id)}
            className="btn btn-hover btn-bluish"
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
}

export default CompanyCard;
