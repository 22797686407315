import React, { useContext } from 'react';
import '../../Styles/SelectSignUpPage.css';
import authImg from '../../assests/images/Auth/loginAs/loginbg.png';
import overLap from '../../assests/images/Auth/loginAs/welcomeImg.png'
import { UserContext } from '../../context/AppContext';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';



function SelectSignUpPage() {
    const screenWidth = window.innerWidth;
    const { setIsCompany } = useContext(UserContext)
    const navigate = useNavigate()

    const redirectSignUp = value => {
        if (value === 'company') {
            setIsCompany(true)
            navigate('/sign-up')
        }
        else {
            setIsCompany(false)
            navigate('/sign-up')
        }
    }

    return (
        <>
            <div className="login-section">
                <div className="container-fluid">
                    <div className="row justify-content-between vh-100">
                        <div className="col-lg-6 login-img bg-cream">
                            <img className='w-100 h-100 overlap-bg' src={authImg} alt="" />
                            <img className={`${screenWidth < 992 ? 'center-img-sm' : 'center-img'}`} src={overLap} alt="" />
                        </div>
                        <div className="col-lg-6 my-5 my-lg-0 align-self-center">
                            <div className={`d-block mx-auto ${screenWidth < 576 ? 'w-90' : 'w-75'}`}>
                                <h3 className="welcome-title">Welcome </h3>
                                <p className={`login-description my-4 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>The #1 platform to recruite skilled, diverse early-career candidates.</p>
                                <button onClick={() => redirectSignUp('intern')} className={`login-button d-block my-3 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>Sign Up As Intern</button>
                                <button onClick={() => redirectSignUp('company')} className={`login-button d-block my-3 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>Sign Up As Company</button>
                            </div>
                            <div className='pt-3'>
                            <p className={`register text-center mt-5 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}><Link to='/' className="span-signUp"><i class="fas fa-arrow-left"></i>  Go Back</Link> </p>
                           </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectSignUpPage
