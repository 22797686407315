/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import female from '../../assests/images/portfolio/female.png'
import male from '../../assests/images/portfolio/male.png'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function InternCard({ data }) {

    const handleDelete = id => {
        fetch(`https://linkintern-backend.herokuapp.com/deleteUser/${id}`, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.reload()
                }
            })
    }

    const confirmDelete = (id) => {
        confirmAlert({
            title: 'Delete Intern',
            message: 'Are you sure to delete this intern?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id)
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    return (
        // <div className="card mb-3 py-4 px-5 intern-card">
        //     <div className="row align-items-center">
        //         <div className="col-md-4">
        //             <img src={data?.resume?.gender === 'female' ? female : male} className="card-img-top" alt="..." className="w-75 d-block mx-auto" />
        //         </div>
        //         <div className="col-md-8">
        //             <div className="card-body">
        //                 <h5>Name: <span className='font-weight-normal'>{data?.resume?.fullName}</span></h5>
        //                 <h5>Location: <span className='font-weight-normal'>{data?.resume?.address}</span></h5>
        //                 <h5>Position: <span className='font-weight-normal'>{data?.resume?.currentPosition ? data?.resume?.currentPosition : 'Fresher'}</span></h5>
        //                 <h5>About: <span className='font-weight-normal'>{data?.resume?.contentLetter.slice(0, 60)}...</span></h5>
        //             </div>
        //             <div className="d-flex justify-content-between align-items-center p-2">
        //                 <a href={`/candidate/${data?._id}`} target='_blank' className="btn btn-primary">View Resume</a>
        //                 <button onClick={() => confirmDelete(data?._id)} className="btn btn-primary">Delete</button>
        //             </div>
        //         </div>
        //     </div>
        // </div>


        <div className='user-intern'>
            <img src={data?.resume?.avatar?.path} alt="" className='user-img'/>
            <h5 className='user-name'><span className='font-weight-normal'>{data?.resume?.fullName}</span></h5>
            <div className='user-info'>
                <p><span className='font-weight-normal'>{data?.resume?.contentLetter.slice(0, 60)}... </span></p>
            </div>
            <p className='user-contact-info'>Location: <span className='font-weight-normal'>{data?.resume?.address}</span></p>
            <p className='user-contact-info'>Email: <span className='font-weight-normal'>{data?.resume?.email}</span></p>
            <p className='user-contact-info'>Tel: <span className='font-weight-normal'>{data?.resume?.tel}</span></p>

            <div className="d-flex justify-content-between align-items-center">
                   <a href={`/candidate/${data?._id}`} target='_blank' className="btn btn-hover btn-bluish">View Resume</a>
               <button onClick={() => confirmDelete(data?._id)} className="btn btn-hover btn-bluish">Delete</button></div>

        </div>
    )
}

export default InternCard
