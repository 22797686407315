import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserContext } from '../../context/AppContext';
import Resume from './Resume'


function ViewResume() {
    const location = useLocation();
    const [isData, setIsData] = useState(true)
    const { setResumeData, setResumeSocialLink} = useContext(UserContext)

    useEffect(() => {
        fetch('https://linkintern-backend.herokuapp.com/get-user')
            .then(res => res.json())
            .then(resData => {
                if (resData.find(data => data._id === `${location.pathname.split("/")[2]}`).hasOwnProperty('resume')) {
                    const candidateResume = resData.find(data => data._id === `${location.pathname.split("/")[2]}`)
                    setIsData(false)
                    setResumeData(candidateResume?.resume)
                    setResumeSocialLink(candidateResume?.resume?.socialLink)
                }
            })


    }, [location.pathname])

    return (
        <div className='vh-100'>
            {
                !isData ?
                    <div className='w-75 d-block mx-auto'>
                        <Resume />
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Uploading...</span>
                        </div>
                    </div>
            }

        </div>
    )
}

export default ViewResume