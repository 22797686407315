import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/AppContext";
import PortfolioMessage from "./PortfolioMessage";
import { allCountries } from "../../database/country";

function PortfolioForm() {
  const [addAnother, setAddAnother] = useState(false);
  const [moreExperience, setMoreExperience] = useState(false);
  const {
    resumeData,
    setResumeData,
    userCredentials,
    resumeSocialLink,
    setResumeSocialLink,
  } = useContext(UserContext);
  const [projectOne, setProjectOne] = useState({});
  const [projectTwo, setProjectTwo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const credentailData = JSON.parse(localStorage.getItem("@credentials"));

  const onClick = () => {
    setAddAnother(true);
  };

  useEffect(() => {
    if (resumeData?.fullName) {
      document.getElementById("fullName").value = resumeData?.fullName || '';
    }
    if (resumeData?.email) {
      document.getElementById("email").value = resumeData?.email || '';
    }
    if (resumeData?.address) {
      document.getElementById("address").value = resumeData?.address || '';
    }
    if (resumeData?.education) {
      document.getElementById("education").value = resumeData?.education || '';
    }
    if (resumeData?.college) {
      document.getElementById("college").value = resumeData?.college || '';
    }
    if (resumeData?.passingYear) {
      document.getElementById("passingYear").value = resumeData?.passingYear || '';
    }
    if (resumeData?.CGPA) {
      document.getElementById("CGPA").value = resumeData?.CGPA || '';
    }
    if (resumeData?.companyInfo) {
      document.getElementById("companyInfo").value = resumeData?.companyInfo || '';
    }
    if (resumeData?.position) {
      document.getElementById("position").value = resumeData?.position || '';
    }
    if (resumeData?.workingYear) {
      document.getElementById("workingYear").value = resumeData?.workingYear || '';
    }
    if (resumeData?.companyInfo2) {
      document.getElementById("companyInfo2").value = resumeData?.companyInfo2 || '';
    }
    if (resumeData?.position2) {
      document.getElementById("position2").value = resumeData?.position2 || '';
    }
    if (resumeData?.workingYear2) {
      document.getElementById("workingYear2").value = resumeData?.workingYear2 || '';
    }
    if (resumeData?.tel) {
      document.getElementById("tel").value = resumeData?.tel || '';
    }
    if (resumeData?.contentLetter) {
      document.getElementById("contentLetter").value =
        resumeData?.contentLetter || '';
    }
    if (resumeData?.hasOwnProperty("socialLink")) {
      document.getElementById("twitter").value =
        resumeData?.socialLink["Twitter"] || '';
      document.getElementById("linkedin").value =
        resumeData?.socialLink["Linkedin"] || '';
      document.getElementById("github").value =
        resumeData?.socialLink["Github"] || '';
      document.getElementById("other").value = resumeData?.socialLink["Other"] || '';
    }

    if (resumeData?.skills?.length) {
      setSkills(resumeData?.skills);
      document.getElementById("skill1").value = resumeData.skills[0].name || '';
      document.getElementById("skill1-percentage").value =
        resumeData.skills[0].value || '';

      document.getElementById("skill2").value = resumeData.skills[1].name || '';
      document.getElementById("skill2-percentage").value =
        resumeData.skills[1].value || '';

      document.getElementById("skill3").value = resumeData.skills[2].name || '';
      document.getElementById("skill3-percentage").value =
        resumeData.skills[2].value || '';
    }

    if (resumeData?.hasOwnProperty("projects")) {
      if (resumeData?.projects[0]?.projectOneName?.length !== 0) {
        setProjectOne(resumeData?.projects[0]);
        document.getElementById("projectOneName").value =
          resumeData?.projects[0]?.projectOneName || '';
        document.getElementById("projectOneLink").value =
          resumeData?.projects[0]?.projectOneLink || '';
        document.getElementById("projectOneDetails").value =
          resumeData?.projects[0]?.projectOneDetails || '';
      }
      if (resumeData?.projects[1]?.projectTwoName?.length !== 0) {
        setProjectTwo(resumeData?.projects[1]);
        if (document.getElementById("projectTwoName")) {
          document.getElementById("projectTwoName").value =
            resumeData?.projects[1]?.projectTwoName || '';
          document.getElementById("projectTwoLink").value =
            resumeData?.projects[1]?.projectTwoLink || '';
          document.getElementById("projectTwoDetails").value =
            resumeData?.projects[1]?.projectTwoDetails || '';
        }
      }
      if (resumeData?.industry) {
        document.getElementById("industry").value = resumeData?.industry || '';
      }
    }
  }, [resumeData]);


  const handlePortfolioSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResumeData({
      ...resumeData,
      projects: [projectOne, projectTwo],
      socialLink: resumeSocialLink,
      skills,
    });

    fetch(
      `https://linkintern-backend.herokuapp.com/resume-update?_id=${userCredentials?._id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...resumeData,
          projects: [projectOne, projectTwo],
          socialLink: resumeSocialLink,
          skills,
        }),
      }
    ).then((res) => {
      if (res) {
        setIsLoading(false);
        localStorage.setItem(
          "@credentials",
          JSON.stringify({
            ...credentailData,
            resume: {
              ...resumeData,
              projects: [projectOne, projectTwo],
              socialLink: resumeSocialLink,
              skills,
            },
          })
        );
        window.location.reload();
      }
    });
  };

  const handleChange = (e) => {
    const updatedForm = { ...resumeData };
    updatedForm[e.target.name] = e.target.value;
    setResumeData(updatedForm);
  };

  const handleSocialMedia = (e) => {
    const updatedSocialLink = { ...resumeSocialLink };
    updatedSocialLink[e.target.name] = e.target.value;
    setResumeSocialLink(updatedSocialLink);
  };

  const handleProject = (e) => {
    if (
      e.target.name === "projectOneName" ||
      e.target.name === "projectOneLink" ||
      e.target.name === "projectOneDetails"
    ) {
      const updatedProject = { ...projectOne };
      updatedProject[e.target.name] = e.target.value;
      setProjectOne(updatedProject);
    } else {
      const updatedProject = { ...projectTwo };
      updatedProject[e.target.name] = e.target.value;
      setProjectTwo(updatedProject);
    }
  };

  const handleSkill = (e) => {
    if (
      e.target.value !== "" &&
      skills.findIndex((dt) => dt.name === e.target.value) === -1 &&
      skills.length < 3
    ) {
      const updatedSkill = [...skills];
      updatedSkill.push({ name: e.target.value });
      setSkills(updatedSkill);
    }

  };

  const handleSkillPercentage = (e) => {
    if (e.target.value !== "") {
      const updatedSkills = [...skills];

      if (e.target.name === "skill1-percentage" && !!updatedSkills[0]) {
        updatedSkills[0].value = e.target.value;
      } else if (e.target.name === "skill2-percentage" && !!updatedSkills[1]) {
        updatedSkills[1].value = e.target.value;
      } else if (e.target.name === "skill3-percentage" && !!updatedSkills[2]) {
        updatedSkills[2].value = e.target.value;
      }

      setSkills(updatedSkills);
    }
  };


  return (
    <form onSubmit={handlePortfolioSubmit}>
      <h5 className="form-title">Personal Details</h5>
      <div className="border-line" />
      <div className="row my-3">
        <div className="col-md-6 ">
          <div className="form-group">
            <label for="fullName" className="lebel-title">
              Full Name
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="fullName"
              name="fullName"
              placeholder="Enter Your Full Name"
              aria-describedby="emailHelp"
              required
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="email" className="lebel-title">
              Email
            </label>
            <input
              onChange={handleChange}
              type="email"
              className="form-control w-100"
              id="email"
              name="email"
              placeholder="Enter Your E-mail"
              aria-describedby="emailhelp"
              required
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="address" className="lebel-title">
              Location
            </label>
            <select
              className="form-control w-100"
              id="address"
              name="address"
              onChange={handleChange}
              required
            >
              <option disabled selected>
                Select Location
              </option>
              {allCountries.map((country) => (
                <option>{country}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="tel" className="lebel-title">
              Phone Number
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="tel"
              name="tel"
              placeholder="Enter Your Phone Number"
              aria-describedby="numberhelp"
              required
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="college" className="lebel-title">
              School/College
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="college"
              name="college"
              placeholder="Enter Your Institute Name"
              aria-describedby="collegehelp"
              required
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="education" className="lebel-title">
              Education Level
            </label>
            <select
              className="form-control w-100"
              id="education"
              name="education"
              onChange={handleChange}
              required
            >
              <option disabled selected>
                Select Education
              </option>
              <option>Student</option>
              <option>Bachelor's Degree</option>
              <option>Master's Degree</option>
              <option>Others</option>
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="passingYear" className="lebel-title">
              Passing Year
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="passingYear"
              name="passingYear"
              placeholder="From 2000 To 2004"
              aria-describedby="passingYearhelp"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="CGPA" className="lebel-title">
              GPA
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="CGPA"
              name="CGPA"
              placeholder="4.00"
              aria-describedby="CGPAhelp"
              required
            />
          </div>
        </div>
      </div>

      {/* Job industry */}
      <h4 className="form-title">Your Job Industry</h4>
      <div className="border-line" />
      <div className="col-md-6 px-0">
        <div className="form-group">
          <label for="industry" className="lebel-title py-2">
            Job Industry
          </label>
          <select
            className="form-control"
            id="industry"
            name="industry"
            onChange={handleChange}
            required
          >
            <option disabled selected>
              Select your industry
            </option>
            <option>Finance, Management & Ops</option>
            <option>Arts & Fashion</option>
            <option>Networking Opportunities</option>
            <option>Sales & Marketing</option>
            <option>Health & Science</option>
            <option>Product & Engineering</option>
            <option>Education</option>
            <option>Software Development</option>
            <option>Service</option>
            <option>Law & Journalism</option>
          </select>
        </div>
      </div>

      {/* Experiance */}

      <h4 className="form-title">Experience</h4>
      <div className="border-line" />
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label for="position" className="lebel-title">
              Role/Position
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="position"
              name="position"
              placeholder="position"
              aria-describedby="positionhelp"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="companyInfo" className="lebel-title">
              Company Name
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="companyInfo"
              name="companyInfo"
              placeholder="Company Name"
              aria-describedby="companyInfohelp"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="workingYear" className="lebel-title">
              Year
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100"
              id="workingYear"
              name="workingYear"
              placeholder="Jul 2021 - Sep 2021"
              aria-describedby="workingYearhelp"
              required
            />
          </div>
        </div>
      </div>

      {!moreExperience && (
        <div className="col-md-12 my-3 pl-0">
          <div className="form-group">
            <label
              for="Addproject"
              className="lebel-title add-another"
              onClick={() => setMoreExperience(true)}
            >
              Add Another <i className="fas fa-plus-circle"></i>
            </label>
          </div>
        </div>
      )}

      {(moreExperience || (resumeData?.companyInfo2 && resumeData?.workingYear2 && resumeData?.position2)) &&
        <div className="row my-3">
          <div className="col-md-6">
            <div className="form-group">
              <label for="position" className="lebel-title">
                Role/Position
              </label>
              <input
                onChange={handleChange}
                type="text"
                className="form-control w-100"
                id="position2"
                name="position2"
                placeholder="position"
                aria-describedby="positionhelp"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="companyInfo" className="lebel-title">
                Company Name
              </label>
              <input
                onChange={handleChange}
                type="text"
                className="form-control w-100"
                id="companyInfo2"
                name="companyInfo2"
                placeholder="Company Name"
                aria-describedby="companyInfohelp"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="workingYear" className="lebel-title">
                Year
              </label>
              <input
                onChange={handleChange}
                type="text"
                className="form-control w-100"
                id="workingYear2"
                name="workingYear2"
                placeholder="Jul 2021 - Sep 2021"
                aria-describedby="workingYearhelp"
                required
              />
            </div>
          </div>
        </div>
      }

      {/* Cover letter */}

      <div className="row">
        <div className="col-md-12">
          <PortfolioMessage handleChange={handleChange} />
        </div>

        <div className="col-md-6 my-3">
          <div className="form-group">
            <label for="linkedin" className="lebel-title">
              Linkedin
            </label>
            <input
              onBlur={handleSocialMedia}
              type="text"
              className="form-control w-100 py-4"
              id="linkedin"
              name="Linkedin"
              placeholder="Linkedin"
              aria-describedby="linkedinHelp"
            />
          </div>
        </div>

        <div className="col-md-6 my-3">
          <div className="form-group">
            <label for="twitter" className="lebel-title">
              Twitter
            </label>
            <input
              onBlur={handleSocialMedia}
              type="text"
              className="form-control w-100 py-4"
              id="twitter"
              name="Twitter"
              placeholder="Twitter"
              aria-describedby="twitterhelp"
            />
          </div>
        </div>

        <div className="col-md-6 my-3">
          <div className="form-group">
            <label for="github" className="lebel-title">
              Github
            </label>
            <input
              onBlur={handleSocialMedia}
              type="text"
              className="form-control w-100 py-4"
              id="github"
              name="Github"
              placeholder="Github"
            />
          </div>
        </div>

        <div className="col-md-6 my-3">
          <div className="form-group">
            <label for="other" className="lebel-title">
              Other
            </label>
            <input
              onBlur={handleSocialMedia}
              type="text"
              className="form-control w-100 py-4"
              id="other"
              name="Other"
              placeholder="Other"
            />
          </div>
        </div>

        {/* Add Skill */}

        <div className="col-md-12 my-3">
          <h4 className="form-title">Add Your Skills</h4>
          <div className="border-line" />
          <div className="row">
            <div className="col-md-6 my-1">
              <div className="form-group">
                <label for="skill1" className="lebel-title">
                  Add Skill
                </label>
                <input
                  onBlur={handleSkill}
                  type="text"
                  className="form-control w-100 py-4"
                  id="skill1"
                  name="skill1"
                  placeholder="Add Skill"
                  required
                />
              </div>
            </div>

            <div className="col-md-6 my-1">
              <div className="form-group">
                <label for="skill1-percentage" className="lebel-title">
                  Percentage
                </label>
                <input
                  onBlur={handleSkillPercentage}
                  type="number"
                  className="form-control w-100 py-4"
                  id="skill1-percentage"
                  name="skill1-percentage"
                  placeholder="Enter Percentage"
                  max='100'
                  required
                />
              </div>
            </div>

            <div className="col-md-6 my-1">
              <div className="form-group">
                <label for="skill2" className="lebel-title">
                  Add Skill
                </label>
                <input
                  onBlur={handleSkill}
                  type="text"
                  className="form-control w-100 py-4"
                  id="skill2"
                  name="skill2"
                  placeholder="Add Skill"
                  required
                />
              </div>
            </div>

            <div className="col-md-6 my-1">
              <div className="form-group">
                <label for="skill2-percentage" className="lebel-title">
                  Percentage
                </label>
                <input
                  onBlur={handleSkillPercentage}
                  type="number"
                  className="form-control w-100 py-4"
                  id="skill2-percentage"
                  name="skill2-percentage"
                  placeholder="Enter Percentage"
                  required
                />
              </div>
            </div>

            <div className="col-md-6 my-1">
              <div className="form-group">
                <label for="skill3" className="lebel-title">
                  Add Skill
                </label>
                <input
                  onBlur={handleSkill}
                  type="text"
                  className="form-control w-100 py-4"
                  id="skill3"
                  name="skill3"
                  placeholder="Add Skill"
                  required
                />
              </div>
            </div>

            <div className="col-md-6 my-1">
              <div className="form-group">
                <label for="skill3-percentage" className="lebel-title">
                  Percentage
                </label>
                <input
                  onBlur={handleSkillPercentage}
                  type="number"
                  className="form-control w-100 py-4"
                  id="skill3-percentage"
                  name="skill3-percentage"
                  placeholder="Enter Percentage"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Portfolio project */}

      <h4 className="form-title">Add Portfolio Projects</h4>
      <div className="border-line" />
      <div className="row my-3">
        <div className="col-md-6 ">
          <div className="form-group">
            <label for="projectOneName" className="lebel-title">
              Project Name
            </label>
            <input
              onChange={handleProject}
              type="text"
              className="form-control w-100 py-4"
              id="projectOneName"
              name="projectOneName"
              placeholder="Project Name"
              aria-describedby="projectOneHelp"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label for="projectOneLink" className="lebel-title">
              Project Link
            </label>
            <input
              onChange={handleProject}
              type="text"
              className="form-control w-100 py-4"
              id="projectOneLink"
              name="projectOneLink"
              placeholder="Project Link"
              aria-describedby="projectoneLinkphelp"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label for="projectOneDetails" className="lebel-title">
              Project Details
            </label>
            <textarea
              id="projectOneDetails"
              name="projectOneDetails"
              onChange={handleProject}
              className="form-control w-100"
              rows="8"
            />
          </div>
        </div>

        {!addAnother && (
          <div className="col-md-12">
            <div className="form-group">
              <label
                for="Addproject"
                className="lebel-title add-another"
                onClick={onClick}
              >
                Add Another <i className="fas fa-plus-circle"></i>
              </label>
            </div>
          </div>
        )}

        {addAnother ? (
          <>
            <div className="col-md-6 ">
              <div className="form-group">
                <label for="projectTwoName" className="lebel-title">
                  Project Name
                </label>
                <input
                  onChange={handleProject}
                  type="text"
                  className="form-control w-100 py-4"
                  id="projectTwoName"
                  name="projectTwoName"
                  placeholder="Project Name "
                  aria-describedby="project2Help"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label for="projectTwoLink" className="lebel-title">
                  Project Link
                </label>
                <input
                  onChange={handleProject}
                  type="text"
                  className="form-control w-100 py-4"
                  id="projectTwoLink"
                  name="projectTwoLink"
                  placeholder="Project Link"
                  aria-describedby="projectlink2help"
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label for="projectTwoDetails" className="lebel-title">
                  Project Details
                </label>
                <textarea
                  onChange={handleProject}
                  id="projectTwoDetails"
                  name="projectTwoDetails"
                  className="form-control w-100"
                  rows="8"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>

      {!isLoading ? (
        <button className="main-button py-2 px-5">Submit</button>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Uploading...</span>
          </div>
        </div>
      )}
    </form>
  );
}

export default PortfolioForm;
