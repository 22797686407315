import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/AppContext";
import '../../Styles/BusinessDetails.css';

const axios = require('axios');

function BusinessDetails() {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate()
  const { stBusinessDetails, businessDetails, userCredentials, setStepperCount } = useContext(UserContext)
  const [img, setImg] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleBusinessDetails = e => {
    const updatedInfo = { ...businessDetails }
    updatedInfo[e.target.name] = e.target.value
    stBusinessDetails(updatedInfo)
  }

  const handleImageUpload = e => {
    setImg(e.target.files[0]);
  };

  const handleSubmit = e => {
    setStepperCount(1)
    e.preventDefault();
    setIsLoading(true)
    const imageData = new FormData();
    imageData.set('key', '87015992ba8ff4ec487f63e035eb5e6c');
    imageData.append('image', img);
    axios
      .post('https://api.imgbb.com/1/upload', imageData)
      .then(result => {
        if (result?.data?.data?.display_url) {
          stBusinessDetails({ ...businessDetails, logo: result?.data?.data?.display_url })
          fetch(`https://linkintern-backend.herokuapp.com/business-detail-update?_id=${userCredentials?._id}`, {
            method: 'PATCH',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...businessDetails, logo: result?.data?.data?.display_url })
          })
            .then(res => {
              if (res) {
                e.target.reset()
                navigate('/dashboard/subscription', { replace: true })
              }
            })
        }
      })
  }

  useEffect(() => {
    if (businessDetails?.companyName) {
      document.getElementById('companyName').value = businessDetails?.companyName || '';
      document.getElementById('aboutCompany').value = businessDetails?.aboutCompany || '';
      document.getElementById('companyUrl').value = businessDetails?.companyUrl || '';
    }
  }, [businessDetails])

  return (
    <>
      <div className="p-md-5 my-4">
        <form className={`${screenWidth < 1024 ? "w-100" : "w-50 d-block mx-auto"}`} onSubmit={handleSubmit}>
          <div
            className="form-group"
          >
            <label for="companyName" className="lebel-title py-2">
              Name of company
            </label>
            <input
              type="text"
              className="form-control py-4 w-100"
              id="companyName"
              name="companyName"
              onChange={handleBusinessDetails}
              placeholder="Enter Name Of Your Company"
              required
            />
          </div>

          <div
            className="form-group"
          >
            <label for="aboutCompany" className="lebel-title py-2">
              Brief about your company
            </label>
            <textarea
              onChange={handleBusinessDetails}
              id='aboutCompany'
              name='aboutCompany'
              className="form-control py-4 w-100"
              rows='8'
              required
            />
          </div>

          <div
            className="form-group">
            <label for="url" className="lebel-title py-2">
              The URL of the business
            </label>
            <input
              onChange={handleBusinessDetails}
              type="text"
              className={`form-control py-4 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}
              id="companyUrl"
              name="companyUrl"
              placeholder="Enter URL of the business"
              aria-describedby="urlHelp"
              required
            />
          </div>

          <div
            className={`form-group mr-5 ${screenWidth < 1024 ? "w-100" : "w-50"
              }`}
          >
            <label for="logo" className="lebel-title py-2">
              Upload a logo
            </label><br />
            <input onChange={handleImageUpload} type="file" className="custom-file-upload" id="validatedCustomFile" required />

          </div>

          {
            !isLoading ?
              <button type='submit' className="next-button my-3">Next</button>
              :
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Uploading...</span>
                </div>
              </div>
          }

        </form>
      </div>

    </>
  );
}

export default BusinessDetails;
