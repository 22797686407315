/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect, useContext } from 'react';
import female from '../../assests/images/portfolio/female.png';
import male from '../../assests/images/portfolio/male.png';
import { UserContext } from '../../context/AppContext';
import '../../Styles/CandidateSection.css';
import InterviewModal from './InterviewModal';
import { NotificationContainer, NotificationManager } from 'react-notifications';


function CandidateCard({ candidate }) {
    const [companyJob, setCompanyJob] = useState([])
    const [companyDetails, setCompanyDetails] = useState({})
    const { resume } = candidate
    const { allCompany, setShowResume, setShowResumeId } = useContext(UserContext)

    useEffect(() => {
        const email = JSON.parse(localStorage.getItem('@credentials'))?.email
        const jobData = allCompany?.find(companyInfo => companyInfo?.email === email);
        setCompanyDetails(jobData)
        if (jobData?.hasOwnProperty('job')) {
            setCompanyJob(jobData?.job)
        }
    }, [allCompany])

    const handleError = () => {
        if (!companyJob?.length) {
            NotificationManager.error('', "You haven't posted any job yet!", 4000);
        }
    }

    return (
        <>

            {/* <div className="row p-lg-3">
                    <div className="col-md-6">

                        <div className="d-flex  align-items-center">
                            <img src={curly} alt="" />
                            <h5 className="candidate-title my-2 ml-2">Jonathan Smith <br /><span className="candidate-subtitle">UI/UX Design</span></h5>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className={`py-3 d-flex align-items-center justify-content-center ${screenWidth > 769 ? 'text-right' : screenWidth < 530 ? 'text-center' : 'text-left'}`}>
                            <button className='btn'><i className="far fa-file-alt icon-btn mr-3"></i></button> <button className="view-button">View Details</button>
                        </div>
                    </div>
                </div> */}
            <NotificationContainer />

            {/* <div className="card candidate-card m-2 p-3" >
                    <img src={resume?.gender === 'female' ? female : male} className="card-img-top" alt="..." className="w-40 d-block mx-auto" />
                    <div className="card-body">
                        <h5 className="candidate-card-title">{resume?.fullName}</h5>
                        <h5 className="candidate-position">{resume?.currentPosition ? resume?.currentPosition : 'Fresher'}</h5>
                        <p className="candidate-card-text text-dark text-center">{resume.contentLetter}...</p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center p-2">
                        <a href={`/candidate/${candidate?._id}`} target='_blank' className="btn btn-primary">View Resume</a>
                        <div className='px-0'>

                            <i onClick={() => handleError()} className="fas fa-comment-alt candidate-icon pl-2" data-toggle="modal" data-target="#staticBackdrop"></i>

                            {
                                companyJob?.length ?
                                    <InterviewModal companyDetails={companyDetails} candidate={candidate} jobs={companyJob} />
                                    : <></>
                            }
                        </div>
                    </div>
                </div> */}

            <div className='user-intern'>
                <img src={resume?.avatar?.gender === 'female' ? resume?.avatar?.path : resume?.avatar?.path} alt="" className='user-img' />
                <h5 className='user-name'><span className='font-weight-normal'>{resume?.fullName}</span></h5>
                <div className='user-info'>
                    <p><span className='font-weight-normal candidate-card-text'>{resume?.contentLetter}... </span></p>
                </div>
                <p className='user-contact-info'>Location: <span className='font-weight-normal'>{resume?.address}</span></p>
                <p className='user-contact-info'>Email: <span className='font-weight-normal'>{resume?.email}</span></p>
                <p className='user-contact-info'>Tel: <span className='font-weight-normal'>{resume?.tel}</span></p>

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <a href={`/candidate/${candidate?._id}`} target='_blank' className="btn btn-hover btn-bluish">View Resume</a>
                    <div className='px-0'>

                        <i onClick={() => handleError()} className="fas fa-comment-alt candidate-icon navy-blue pl-2" data-toggle="modal" data-target="#staticBackdrop"></i>

                        {
                            companyJob?.length ?
                                <InterviewModal companyDetails={companyDetails} candidate={candidate} jobs={companyJob} />
                                : <></>
                        }
                    </div>
                </div>

            </div>

        </>
    )
}

export default CandidateCard
