import React from 'react';
import '../../Styles/CandidateSection.css';
import mask from '../../assests/images/new/mask1.png';
import portfolio from '../../assests/images/new/student-portfolio.png'


function CandidateSection() {
    return (
        <>
            <div className="candidate-section ">
            <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-7 col-md-6">
                            <div className='howItWorks'>
                                <h3 className='candidate-title-two'>How it works</h3>
                                <div className='candidate-details'>
                                
                                <h3 className="company-title-one my-3 my-md-0">For students</h3>
                                <p className="company-description-one mt-1 mb-1">Finding the job of your dreams is just a few clicks away.</p>

                                <div className='mb-3'>
                                    <img src={mask} alt="" className='round-image-one' />
                                    {/* <span>1</span> */}
                                    {/* <img src={one} alt="" className='one-img-two'/> */}
                                </div>
                                <h5 className='right-candidate-one'>Create a portfolio for FREE</h5>
                                <div className='sub-line-two'></div>
                                 <p className='company-description-one'>Create your portfolio and get discovered by top European employers. Let the job offers come to you and find internships or entry-level jobs. Your future is bright, and it starts here.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 p-0 col-md-6">
                            <img src={portfolio} alt="" className="w-100 d-block mx-auto" />
                     
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateSection
