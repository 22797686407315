import React from 'react';
import '../../Styles/About.css';
import aboutImg from '../../assests/images/new/Eunice Hammond-Mørklid 1.jpeg';
import Navbar from '../../components/Common/Navbar';
import Footer from "../../components/Common/Footer";
import FooterTwo from '../../components/Common/FooterTwo';

function About() {
    return (
        <>
            <Navbar />
            <div className='bg-cream'>
                <div className="about-section">
                    <div >
                        <div className="container my-4 my-md-0">
                            <div className="row aling-items-center py-md-5 py-0  ">
                                <div className="col-lg-6 order-1 order-md-0 align-self-center my-3 my-md-0">
                                    <h3 className="about-title my-4">Who <span className="title-span">We Are?</span></h3>
                                    <p className="about-us-description">
                                        At LinkIntern, our mission is to stand at the intersection of top talent and dynamic european companies, building the bridge to a better tomorrow.<br />
                                        The job market in Europe is significantly impacted by the fact that the proportion of women in ICT sector is not the result of individual preference, but of systematic obstacles.  Second, that the limited participation of women is detrimental for industry and the wider economy. This produces a labor environment where women and youth find it difficult to gain formal employment, especially in high-skilled occupations. Securing jobs is still a challenge for young, diverse and ambitious individuals.<br />
                                        Boosting the number of women in ICT is a major opportunity for Europe. Industries needs highly skilled employees, and women make up the majority of graduates from tertiary education<br />
                                        LinkIntern was created to change this paradigm, as we believe in equal opportunities. We are a group of dedicated people who want to change the way entry-level employees are hired in Tech. We believe that if you’re good at something, you deserve a chance to be recognized. It should not be about gender, who you know, or where you come from - it should be about your skills, performance, and passion.<br />
                                        At the same time, LinkIntern aims to support the growth of Tech companies by supplying them with top talent. Our career platform removes the barriers of cost, location, and access. With LinkIntern, HR people can instantly book and schedule multiple interviews and rate the interviews instantly for hiring.<br />
                                        In short, we are here to help graduates uncover opportunities for their future that they never knew existed while empowering businesses with a brilliant, potential-full workforce.</p>
                                    <h5 className="about-us-subcontent">Are you going to join our efforts to secure the future of Europe?</h5>
                                </div>
                                <div className="col-lg-6 order-0 order-md-1 align-self-center">
                                    <img src={aboutImg} alt="" className="w-100" />
                                    <p className="name">Eunice, the founder of LinkIntern</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </>
    )
}

export default About
