import React from 'react'
import '../../Styles/CompanySection.css';
import companyImg from '../../assests/images/new/client-search.png'
import mask from '../../assests/images/new/mask2.png';
import one from '../../assests/images/new/1.png';
// import icon1 from '../../assests/images/Home/company/icon1.png'
// import icon2 from '../../assests/images/Home/company/icon2.png'
// import icon3 from '../../assests/images/Home/company/icon3.png'


// const companyDetails = [
//     {
//         icon: icon1,
//         bg: 'green',
//         title: 'Quickly Asses Multiple Candidates',
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
//     },
//     {
//         icon: icon2,
//         bg: 'pink',
//         title: 'Check The Area Expertise',
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
//     },
//     {
//         icon: icon3,
//         bg: 'light',
//         title: 'Check Availability',
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
//     },
// ]

function CompanySection() {
    return (
        <>
            <div className="company-section">
                <div className="container">
                    <div className="row align-items-center g-0">
                        <div className="col-lg-5 col-md-6 p-0">
                            <img src={companyImg} alt="" className="w-100" />
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className='companyEmployeers'>
                                <div className='company-details'>
                                    <h3 className="for-company my-3 my-md-0">For companies</h3>
                                    <p className="company-description-one mt-3 mb-3">Finding skilled and enthusiastic interns has never been easier. Here’s what LinkIntern does for companies</p>
                                    
                                    <div className='mb-3'>
                                        <img src={mask} alt="" className='round-image' />
                                        {/* <img src={one} alt="" className='one-img'/> */}
                                    </div>
                                    <h5 className='right-candidate-one'>Helps you find the right candidate</h5>
                                    <div className='sub-line'></div>
                                    <p className='company-description-one '>All you have to do is sign in, post a job and send an interview request.</p>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanySection
