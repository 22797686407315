import React, { useContext, useState } from 'react'
import "../../Styles/SignInPage.css";
import signInImg from "../../assests/images/Auth/signInPage/SignInImg.png";
import authImg from '../../assests/images/Auth/loginAs/loginbg.png'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/AppContext';
import emailjs from "emailjs-com";

const ResetPassword = () => {
    const screenWidth = window.innerWidth;
    const navigate = useNavigate();
    const { userCredentials } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [authCode, setAuthCode] = useState();
    const [isReset, setIsReset] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const resetCode = Math.round(Math.random() * 999999).toString();
    const [newCredentials, setNewCredentials] = useState({});

    const handleChange = (e) => {
        const updatedCredentials = { ...newCredentials };
        updatedCredentials[e.target.name] = e.target.value;
        setNewCredentials(updatedCredentials);
    };

    const handleEmail = (e) => {
        setUserEmail(e.target.value);
    }

    const handleAuthCode = (e) => {
        setAuthCode(e.target.value)
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.target.reset();
        if (!emailSent && !authCode) {
            sessionStorage.setItem('resetEmail', JSON.stringify(userEmail));
            const message1 = `This is the password reset code for your account. Please enter this code to reset your password.`;

            const message2 = `${resetCode}`

            sessionStorage.setItem('authCode', JSON.stringify(resetCode));

            emailjs.send('service_xl3ng7t', 'template_71zk6s8', { candidate: userCredentials?.fullName, message1: message1, message2: message2, toEmail: userEmail, replyEmail: "noreply@linktern.com" }, "user_kosMS0TM9jXFWW4Cerip0")
                .then((result) => {
                    setEmailSent(true);
                    setTimeout(() => {
                        NotificationManager.success('', 'Email sent successfully!', 4000);
                    }, 1000)
                }, (error) => {
                    console.log(error.text);
                });
        }
        else if (authCode === JSON.parse(sessionStorage.getItem('authCode'))) {
            setIsReset(true);
            fetch("https://linkintern-backend.herokuapp.com/get-user")
                .then(res => res.json())
                .then(data => {
                    const currentUser = data.find(item => item.email === JSON.parse(sessionStorage.getItem('resetEmail')));
                    fetch(
                        `https://linkintern-backend.herokuapp.com/password-update?_id=${currentUser?._id}`,
                        {
                            method: "PATCH",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(newCredentials),
                        }
                    ).then(res => res.json())
                        .then(resData => {
                            if (resData) {
                                setTimeout(() => {
                                    NotificationManager.success('', 'Password Reset Successful', 4000);
                                }, 1000)
                                sessionStorage.removeItem('authCode');
                                sessionStorage.removeItem('resetEmail')
                                navigate('/sign-in')
                            }
                        })
                })
        }
        else {
            setTimeout(() => {
                NotificationManager.error('', 'Wrong reset code!', 4000);
            }, 1000)
        }

    };


    return (
        <div className="login-section">
            <div className="container-fluid">
                <NotificationContainer />
                <div className="row justify-content-between vh-100">
                    <div className="col-lg-6 login-img bg-cream">
                        <img className='w-100 h-100 overlap-bg' src={authImg} alt="" />

                        <img className={`${screenWidth < 576 ? 'center-img-sm' : screenWidth < 992 ? 'center-img-md' : 'center-img'}`} src={signInImg} alt="" />
                    </div>
                    <div className="col-lg-6 my-5 my-lg-0 align-self-center">
                        <div className={`d-block mx-auto ${screenWidth < 576 ? 'w-90' : 'w-75'}`}>
                            <h3 className="login-title">Reset Your Password</h3>

                            <div className="login-form">
                                <form onSubmit={e => handleSubmit(e)}>
                                    {
                                        emailSent ?
                                            isReset ?
                                                <>
                                                    <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                        <label for="password" className="lebel-title">
                                                            New Password
                                                        </label>
                                                        <input
                                                            onChange={handleChange}
                                                            type="password"
                                                            className="form-control w-100"
                                                            id="password"
                                                            name='password'
                                                            placeholder="Enter Your New Password..."
                                                            value={newCredentials.ResetPassword}
                                                            aria-describedby="passwordHelp"
                                                            required
                                                        />
                                                    </div>
                                                    <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                        <label for="confirm-password" className="lebel-title">
                                                            Confirm Password
                                                        </label>
                                                        <input
                                                            onChange={handleChange}
                                                            type="password"
                                                            className="form-control w-100"
                                                            id="confirm-password"
                                                            name='confirm-password'
                                                            placeholder="Confirm Your New Password..."
                                                            aria-describedby="confirmPasswordHelp"
                                                            required
                                                        />
                                                    </div>

                                                    {
                                                        emailSent ?
                                                            !isLoading ?
                                                                <button type="submit" className={`login-button d-block my-3 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                                    submit
                                                                </button>
                                                                :
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="spinner-border text-primary" role="status">
                                                                        <span className="sr-only">Uploading...</span>
                                                                    </div>
                                                                </div>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                        <label for="resetCode" className="lebel-title">
                                                            Enter the reset code
                                                        </label>
                                                        <input
                                                            onChange={handleAuthCode}
                                                            type="text"
                                                            className="form-control w-100"
                                                            id="resetCode"
                                                            name='resetCode'
                                                            placeholder="code from email"
                                                            aria-describedby="emailHelp"
                                                            required
                                                        />
                                                    </div>

                                                    <button type="submit" className={`login-button d-block my-3 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                        submit
                                                    </button>

                                                </>
                                            :
                                            <>
                                                <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                    <label for="email" className="lebel-title">
                                                        Email
                                                    </label>
                                                    <input
                                                        onChange={handleEmail}
                                                        type="email"
                                                        className="form-control w-100"
                                                        id="email"
                                                        name='email'
                                                        placeholder="Enter Your Email"
                                                        aria-describedby="emailHelp"
                                                        required
                                                    />
                                                </div>

                                                <button type="submit" className={`login-button d-block my-3 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                                                    submit
                                                </button>

                                            </>
                                    }

                                    <p className={`register text-center mt-5 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}><Link to='/' className="span-signUp"><i class="fas fa-arrow-left"></i>  Go Back</Link> </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword