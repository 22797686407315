export const allCountries = [
  "Grenada",
  "Tonga",
  "Christmas Island",
  "Chad",
  "Madagascar",
  "Kuwait",
  "Iraq",
  "Spain",
  "Saint Martin",
  "Nepal",
  "Togo",
  "Belgium",
  "Nigeria",
  "Samoa",
  "Cocos (Keeling) Islands",
  "Northern Mariana Islands",
  "Jamaica",
  "Mongolia",
  "Azerbaijan",
  "French Southern and Antarctic Lands",
  "Bulgaria",
  "Libya",
  "Morocco",
  "Belarus",
  "Thailand",
  "Central African Republic",
  "Lebanon",
  "Puerto Rico",
  "Brazil",
  "Papua New Guinea",
  "Zimbabwe",
  "Marshall Islands",
  "Angola",
  "Rwanda",
  "Laos",
  "Nicaragua",
  "United States",
  "Costa Rica",
  "Turkmenistan",
  "Guinea-Bissau",
  "India",
  "Western Sahara",
  "Suriname",
  "Aruba",
  "Dominica",
  "Netherlands",
  "Montenegro",
  "Kyrgyzstan",
  "Norway",
  "Uruguay",
  "Heard Island and McDonald Islands",
  "Kazakhstan",
  "Bosnia and Herzegovina",
  "Bolivia",
  "Saint Lucia",
  "Palau",
  "French Polynesia",
  "Tajikistan",
  "American Samoa",
  "Honduras",
  "Maldives",
  "Anguilla",
  "Serbia",
  "Sint Maarten",
  "Slovakia",
  "Latvia",
  "Turks and Caicos Islands",
  "Antigua and Barbuda",
  "Cayman Islands",
  "Saint Barthélemy",
  "Tanzania",
  "Ghana",
  "Isle of Man",
  "Åland Islands",
  "United States Minor Outlying Islands",
  "Iran",
  "Namibia",
  "Saint Pierre and Miquelon",
  "Bahamas",
  "Hungary",
  "Moldova",
  "Czechia",
  "Gabon",
  "Italy",
  "South Africa",
  "Palestine",
  "Guinea",
  "Trinidad and Tobago",
  "Monaco",
  "Taiwan",
  "Kenya",
  "Antarctica",
  "Tokelau",
  "South Sudan",
  "Cyprus",
  "Jersey",
  "Finland",
  "Tuvalu",
  "French Guiana",
  "Cambodia",
  "Cuba",
  "Paraguay",
  "Mexico",
  "Turkey",
  "Belize",
  "France",
  "Barbados",
  "Pitcairn Islands",
  "New Zealand",
  "Falkland Islands",
  "Singapore",
  "Svalbard and Jan Mayen",
  "Sri Lanka",
  "Lithuania",
  "Qatar",
  "China",
  "Peru",
  "Austria",
  "Mayotte",
  "Malta",
  "Guernsey",
  "Burkina Faso",
  "Mauritania",
  "Bahrain",
  "Benin",
  "Zambia",
  "Montserrat",
  "Chile",
  "Romania",
  "Macau",
  "Egypt",
  "Niger",
  "Faroe Islands",
  "Ivory Coast",
  "Germany",
  "Burundi",
  "Guam",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Fiji",
  "Guyana",
  "Kosovo",
  "Ethiopia",
  "Sierra Leone",
  "Philippines",
  "Algeria",
  "Senegal",
  "Mozambique",
  "Eritrea",
  "Slovenia",
  "Vanuatu",
  "Solomon Islands",
  "Albania",
  "El Salvador",
  "Saint Vincent and the Grenadines",
  "Liechtenstein",
  "Luxembourg",
  "British Virgin Islands",
  "Mali",
  "Russia",
  "Micronesia",
  "United States Virgin Islands",
  "Ecuador",
  "Canada",
  "Georgia",
  "Bangladesh",
  "Denmark",
  "Dominican Republic",
  "South Korea",
  "Hong Kong",
  "Uganda",
  "Kiribati",
  "Gambia",
  "Japan",
  "Niue",
  "United Kingdom",
  "Cook Islands",
  "British Indian Ocean Territory",
  "Bhutan",
  "Cape Verde",
  "New Caledonia",
  "Botswana",
  "Gibraltar",
  "Curaçao",
  "Eswatini",
  "Ukraine",
  "Sweden",
  "Republic of the Congo",
  "South Georgia",
  "Panama",
  "Norfolk Island",
  "Equatorial Guinea",
  "San Marino",
  "Bermuda",
  "Israel",
  "Iceland",
  "Venezuela",
  "Afghanistan",
  "Guadeloupe",
  "Brunei",
  "DR Congo",
  "North Korea",
  "Andorra",
  "Oman",
  "Réunion",
  "Martinique",
  "United Arab Emirates",
  "Vietnam",
  "Australia",
  "Guatemala",
  "Indonesia",
  "Argentina",
  "Colombia",
  "Croatia",
  "North Macedonia",
  "Djibouti",
  "Switzerland",
  "Armenia",
  "Caribbean Netherlands",
  "Lesotho",
  "Estonia",
  "Saint Kitts and Nevis",
  "Timor-Leste",
  "Tunisia",
  "São Tomé and Príncipe",
  "Bouvet Island",
  "Greece",
  "Saudi Arabia",
  "Poland",
  "Haiti",
  "Syria",
  "Pakistan",
  "Seychelles",
  "Sudan",
  "Malawi",
  "Liberia",
  "Nauru",
  "Jordan",
  "Portugal",
  "Wallis and Futuna",
  "Greenland",
  "Malaysia",
  "Uzbekistan",
  "Myanmar",
  "Cameroon",
  "Somalia",
  "Comoros",
  "Vatican City",
  "Yemen",
  "Mauritius",
  "Ireland"
]