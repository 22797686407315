import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const UserContext = createContext()

function AppContext({ children }) {
    const navigate = useNavigate();
    const [isCompany, setIsCompany] = useState(JSON.parse(localStorage.getItem('@credentials'))?.isCompany || false)
    const [userCredentials, setUserCredentials] = useState(JSON.parse(localStorage.getItem('@credentials')) || { isCompany: true })
    const [businessDetails, stBusinessDetails] = useState({})
    const [subscription, setSubscription] = useState({})
    const [allJobs, setAllJobs] = useState(JSON.parse(localStorage.getItem('@credentials'))?.job || [])
    const [resumeData, setResumeData] = useState(JSON.parse(localStorage.getItem('@credentials'))?.resume || { name: 'male1', gender: 'male' })
    const [resumeSocialLink, setResumeSocialLink] = useState(JSON.parse(localStorage.getItem('@credentials'))?.resume?.socialLink || {})
    const [allCompany, setAllCompany] = useState([])
    const [allIntern, setAllIntern] = useState([])
    const [stepperCount, setStepperCount] = useState(0)
    const [searchFields, setSearchFields] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false)

    useEffect(() => {
        fetch('https://linkintern-backend.herokuapp.com/get-user')
            .then(res => res.json())
            .then(data => {
                if (data?.length) {
                    setAllCompany(data?.filter(info => info?.hasOwnProperty('isCompany')))
                    setAllIntern(data?.filter(info => !info?.hasOwnProperty('isCompany')))
                    setIsUpdated(false)
                }
            })
    }, [isUpdated])


    useEffect(() => {
        if (JSON.parse(localStorage.getItem('@credentials'))) {
            const { created, remember } = JSON.parse(localStorage.getItem('@credentials'));
            if ((remember && Date.now() - created < 1000 * 60 * 60 * 24 * 30) || (Date.now() - created < 1000 * 60 * 60 * 2)) {
                if (JSON.parse(localStorage.getItem('@credentials'))?.isCompany) {
                    const { isCompany, companyName, email, password, agreeTick, _id } = JSON.parse(localStorage.getItem('@credentials'))
                    setUserCredentials({ isCompany, companyName, email, password, agreeTick, _id })
                    stBusinessDetails(JSON.parse(localStorage.getItem('@credentials'))?.businessDetails || {})
                    setSubscription(JSON.parse(localStorage.getItem('@credentials'))?.subscription || {})
                    setIsCompany(isCompany)
                    setAllJobs(JSON.parse(localStorage.getItem('@credentials'))?.job || [])

                    if (JSON.parse(localStorage.getItem('@credentials'))?.hasOwnProperty('businessDetails')) {
                        setStepperCount(1)
                    }
                    if (JSON.parse(localStorage.getItem('@credentials'))?.hasOwnProperty('subscription')) {
                        setStepperCount(1)
                    }
                    if (JSON.parse(localStorage.getItem('@credentials'))?.hasOwnProperty('businessDetails') && JSON.parse(localStorage.getItem('@credentials'))?.hasOwnProperty('subscription')) {
                        setStepperCount(2)
                    }
                    else {
                        setStepperCount(-1)
                    }
                }
                else {
                    const { email, fullName, password, agreeTick, _id } = JSON.parse(localStorage.getItem('@credentials'))
                    setUserCredentials({ email, fullName, password, agreeTick, _id })
                    setResumeData(JSON.parse(localStorage.getItem('@credentials'))?.resume || {})
                    setResumeSocialLink(JSON.parse(localStorage.getItem('@credentials'))?.resume?.socialLink || {})
                }
            }
            else {
                localStorage.removeItem('@credentials')
                setUserCredentials({})
                stBusinessDetails({})
                setSubscription({})
                setAllJobs([])
                setIsCompany(false)
                navigate('/', { replace: true })
            }
        }
    }, [JSON.parse(localStorage.getItem('@credentials'))?.email])

    return (
        <UserContext.Provider value={{
            isCompany,
            setIsCompany,
            userCredentials,
            setUserCredentials,
            businessDetails,
            stBusinessDetails,
            subscription,
            setSubscription,
            allJobs,
            setAllJobs,
            resumeData,
            setResumeData,
            resumeSocialLink,
            setResumeSocialLink,
            allCompany,
            setAllCompany,
            allIntern,
            setAllIntern,
            stepperCount,
            setStepperCount,
            searchFields,
            setSearchFields,
            isUpdated,
            setIsUpdated
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default AppContext
