import React, { useContext, useState } from "react";
import "../../Styles/SignInPage.css";
import signInImg from "../../assests/images/Auth/signInPage/SignInImg.png";
import authImg from '../../assests/images/Auth/loginAs/loginbg.png'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/AppContext";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function SignInPage() {
  const screenWidth = window.innerWidth
  const { setUserCredentials } = useContext(UserContext)
  const [loginData, setLoginData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
  const type = JSON.parse(localStorage.getItem('type'))


  const handleChange = (e) => {
    const newUserInfo = { ...loginData };
    newUserInfo[e.target.name] = e.target.value;
    setLoginData(newUserInfo);
  }

  const handleSubmit = (e) => {
    setIsLoading(true)

    if (loginData?.email === 'super.admin@gmail.com' && loginData?.password === '50114400') {
      localStorage.setItem('@credentials', JSON.stringify({ ...loginData, isCompany: true, created: Date.now() }))
      setUserCredentials(loginData);
      navigate('/super-admin', { replace: true })
      localStorage.removeItem('type')
    }
    else {
      fetch("https://linkintern-backend.herokuapp.com/get-user")
        .then(res => res.json())
        .then(data => {
          const findUser = data?.find(user => user?.email === loginData?.email)
          if (findUser?.email) {
            if (findUser?.password === loginData?.password) {
              setIsLoading(false)
              e.target.reset();
              localStorage.setItem('@credentials', JSON.stringify({ ...findUser, remember: loginData.remember, created: Date.now() }))
              setUserCredentials(JSON.parse(localStorage.getItem('@credentials')))
              navigate(location.state ? location.state : '/dashboard', { replace: true })
              localStorage.removeItem('type')
            }
            else {
              setIsLoading(false)
              NotificationManager.error('Your have entered wrong password', 'Worng Password!', 5000);
            }
          }
          else {
            setIsLoading(false)
            NotificationManager.error('This email is not associated with any account!', "Wrong Email!", 5000);
          }
        })
    }

    e.preventDefault();
  }

  const handleRemember = e => {
    const newUserInfo = { ...loginData };
    newUserInfo[e.target.name] = e.target.checked;
    setLoginData(newUserInfo);
  }

  return (
    <div className="login-section">
      <div className="container-fluid">
        <NotificationContainer />
        <div className="row justify-content-between vh-100">
          <div className="col-lg-6 login-img bg-cream">
            <img className='w-100 h-100 overlap-bg' src={authImg} alt="" />

            <img className={`${screenWidth < 576 ? 'center-img-sm' : screenWidth < 992 ? 'center-img-md' : 'center-img'}`} src={signInImg} alt="" />
          </div>
          <div className="col-lg-6 my-5 my-lg-0 align-self-center">
            <div className={`d-block mx-auto ${screenWidth < 576 ? 'w-90' : 'w-75'}`}>
              <h3 className="login-title">Sign In {type === 'company' ? 'as Company' : type === 'intern' ? 'as Intern' : ''}</h3>
              <p className="login-description pb-2">
              The #1 platform to recruite skilled, diverse early-career candidates.
              </p>

              <div className="login-form">
                <form onSubmit={handleSubmit}>
                  <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                    <label for="email" className="lebel-title">
                      Email
                    </label>
                    <input
                      onChange={handleChange}
                      type="email"
                      className="form-control w-100"
                      id="email"
                      name='email'
                      placeholder="Enter Your E-mail"
                      aria-describedby="emailHelp"
                      required
                    />
                  </div>

                  <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                    <label for="password" className="lebel-title">
                      Password
                    </label>
                    <input
                      onChange={handleChange}
                      type="password"
                      className="form-control"
                      id="password"
                      name='password'
                      placeholder="*******************"
                      required
                    />
                  </div>

                  <div className={`form-group ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                    <div className="clearfix">
                      <label className="float-left form-check-label text-dark">
                        <input name='remember' type="checkbox" onChange={e => handleRemember(e)} /> Remember me
                      </label>
                      <Link to="/reset-password" className="orange-text float-right">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  {
                    !isLoading ?
                      <button type="submit" className={`login-button d-block my-3 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>
                        Sign In
                      </button>
                      :
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Uploading...</span>
                        </div>
                      </div>
                  }

                  <p className={`register text-center mt-5 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}>Not Registered yet? <Link to='/login-as' className="span-signUp">Sign Up</Link> </p>

                  <p className={`register text-center mt-5 ${screenWidth < 769 ? 'w-100' : 'w-82'}`}><Link to='/' className="span-signUp"><i class="fas fa-arrow-left"></i>  Go Back</Link> </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default SignInPage;
