import React, { useContext, useState } from "react";
import signUpCompany from "../../assests/images/Auth/signUpPage/signUpCompany.png";
import signUpIntern from "../../assests/images/Auth/signUpPage/signUPIntern.png";
import authImg from '../../assests/images/Auth/loginAs/loginbg.png';
import { UserContext } from "../../context/AppContext";
import { useNavigate } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Link } from "react-router-dom";

function SignUpPage() {
  const screenWidth = window.innerWidth;
  const { isCompany, setUserCredentials } = useContext(UserContext);
  const [signUpData, setSignUpData] = useState({});
  const navigate = useNavigate();
  const [isPassError, setIsPassError] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    let isFieldValid = true;
    if (e.target.name === "password") {
      if (e.target.value.length < 8 && e.target.value.length !== 0) {
        setIsPassError(true);
      } else {
        setIsPassError(false);
      }
    }
    if (e.target.name === "confirmPassword") {
      if (
        e.target.value !== signUpData?.password &&
        e.target.value.length >= 8
      ) {
        setConfirmPass(true);
      } else {
        setConfirmPass(false);
      }
    }
    if (e.target.name === "email") {
      isFieldValid = /\S+@\S+\.\S+/.test(e.target.value);
    }

    if (isFieldValid) {
      const newUserInfo = { ...signUpData };
      if (e.target.name === "companyName") {
        newUserInfo["isCompany"] = true;
      } else if (e.target.name === "name") {
        newUserInfo["isIntern"] = true;
      }
      newUserInfo[e.target.name] = e.target.value;
      setSignUpData(newUserInfo);
    }
  };

  const handleSubmit = (e) => {
    if (signUpData?.password === signUpData?.confirmPassword) {
      setIsLoading(true);
      fetch("https://linkintern-backend.herokuapp.com/get-user")
        .then((res) => res.json())
        .then((data) => {
          const findUser = data?.find(
            (user) => user?.email === signUpData?.email
          );
          if (findUser?.hasOwnProperty("email")) {
            NotificationManager.error(
              "This email is already registered",
              "User Exist!",
              5000
            );
            e.target.reset();
          } else {
            fetch("https://linkintern-backend.herokuapp.com/create-user", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify(signUpData),
            })
              .then((res) => res.json())
              .then((resData) => {
                if (resData) {
                  fetch("https://linkintern-backend.herokuapp.com/get-user")
                    .then((res) => res.json())
                    .then((data) => {
                      const findUser = data?.find(
                        (user) => user?.email === signUpData?.email
                      );
                      localStorage.setItem(
                        "@credentials",
                        JSON.stringify(findUser)
                      );
                      setUserCredentials(findUser);
                      setIsLoading(false);
                      e.target.reset();
                      navigate("/dashboard", { replace: true });
                    });
                }
              });
          }
        });
    }
    e.preventDefault();
  };

  return (
    <div className="login-section">
      <div className="container-fluid">
        <NotificationContainer />
        <div className="row justify-content-between vh-100">
          <div className="col-lg-6 login-img bg-cream">
            <img className="w-100 h-100 overlap-bg" src={authImg} alt="" />
            <img
              className={`${screenWidth < 992 ? "center-img-sm" : "center-img"
                }`}
              src={isCompany ? signUpCompany : signUpIntern}
              alt=""
            />
          </div>

          <div className="col-lg-6 my-5 my-lg-0 py-5 align-self-center">
            <div
              className={`d-block mx-auto ${screenWidth < 576 ? "w-90" : "w-75"
                }`}
            >
              {isCompany && <h3 className="login-title">Sign Up As Company</h3>}
              {!isCompany && <h3 className="login-title">Sign Up As Intern</h3>}
              <p className="login-description pb-2">
              The #1 platform to recruite skilled, diverse early-career candidates.
              </p>

              <div className="login-form">
                <form onSubmit={handleSubmit}>
                  {isCompany && (
                    <div
                      className={`form-group ${screenWidth < 769 ? "w-100" : "w-82"
                        }`}
                    >
                      <label for="companyName" className="lebel-title">
                        Name of the Company
                      </label>
                      <input
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="companyName"
                        name="companyName"
                        placeholder="Enter Company Name"
                        aria-describedby="companyHelp"
                        required
                      />
                    </div>
                  )}

                  <div
                    className={`form-group ${screenWidth < 769 ? "w-100" : "w-82"
                      }`}
                  >
                    <label for="email" className="lebel-title">
                      {isCompany ? "Company Email" : "Email"}
                    </label>
                    <input
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Your E-mail"
                      aria-describedby="emailHelp"
                      required
                    />
                  </div>

                  {!isCompany && (
                    <div
                      className={`form-group ${screenWidth < 769 ? "w-100" : "w-82"
                        }`}
                    >
                      <label for="fullName" className="lebel-title">
                        Full Name
                      </label>
                      <input
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Enter Full Name"
                        aria-describedby="nameHelp"
                        required
                      />
                    </div>
                  )}

                  <div
                    className={`form-group ${screenWidth < 769 ? "w-100" : "w-82"
                      }`}
                  >
                    <label for="password" className="lebel-title">
                      Password
                    </label>
                    <input
                      onChange={handleChange}
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="*******************"
                      required
                    />
                    {isPassError && (
                      <p className="text-danger">
                        Password have to be 8 charaters long.
                      </p>
                    )}
                  </div>

                  <div
                    className={`form-group ${screenWidth < 769 ? "w-100" : "w-82"
                      }`}
                  >
                    <label for="confirmPassword" className="lebel-title">
                      Confirm Password
                    </label>
                    <input
                      onChange={handleChange}
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="*******************"
                      required
                    />
                    {confirmPass && (
                      <p className="text-danger">Password doesn't match!</p>
                    )}
                  </div>

                  <div className="w-75 form-check my-2 mr-sm-2">
                    <input
                      onChange={handleChange}
                      className="form-check-input"
                      type="checkbox"
                      id="agreeTick"
                      name="agreeTick"
                      required
                    />
                    <label
                      className="form-check-label remember"
                      for="agreeTick"
                    >
                      I agree with the terms of use
                    </label>
                  </div>

                  {!isLoading ? (
                    <button
                      type="submit"
                      className={`login-button d-block my-3 ${screenWidth < 769 ? "w-100" : "w-82"
                        }`}
                    >
                      Submit
                    </button>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Uploading...</span>
                      </div>
                    </div>
                  )}
                </form>
                <p
                  className={`register text-center mt-5 ${screenWidth < 769 ? "w-100" : "w-82"
                    }`}
                >
                  Already have an account?{" "}
                  <Link to="/sign-in" className="span-signUp">
                    Sign In
                  </Link>{" "}
                </p>
                <p
                  className={`register text-center mt-5 ${screenWidth < 769 ? "w-100" : "w-82"
                    }`}
                >
                  <Link to="/" className="span-signUp">
                    <i class="fas fa-arrow-left"></i> Go Back
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
