import React from "react";
import checkmark from "../../assests/images/newHome/checkmark.png";
import wicon1 from "../../assests/images/newHome/portpolioIcon.png";
import wicon2 from "../../assests/images/newHome/profileIcon.png";
import wicon3 from "../../assests/images/newHome/searchIcon.png";
import wicon4 from "../../assests/images/newHome/notebookIcon.png";

const HowWork = () => {
  return (
    <>
      <div class="featureTwo">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div className="step-up">
                {/* <h6 className='miniText'>How it Works</h6> */}
                <h3 className="follow-steps">
                  Follow our steps
                  <br />
                  we will help you.
                </h3>
              </div>

              <div className="check-sm">
              <div className="mt-4 check-m">
                <ul>
                  <li>
                    <img src={checkmark} alt="" className="checkTwomark" />{" "}
                    <span className="stepTwo">Trusted & Quality Job </span>
                  </li>
                  <li>
                    <img src={checkmark} alt="" className="checkTwomark" />{" "}
                    <span className="stepTwo">International Job </span>
                  </li>
                  <li>
                    <img src={checkmark} alt="" className="checkTwomark" />{" "}
                    <span className="stepTwo">No Extra Charge </span>
                  </li>
                  <li>
                    <img src={checkmark} alt="" className="checkTwomark" />{" "}
                    <span className="stepTwo">Top Companies </span>
                  </li>
                </ul>
              </div>
             </div>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="feature-CardOne">
                    <span class="number-list-two-one">01</span>
                    <div className="d-flex">
                      <div className="w-icon1">
                        <img src={wicon4} alt="" className="w-img-1" />
                      </div>
                      <h3 className="feature-work-title">
                        Register
                        <br />
                        Your Account
                      </h3>
                    </div>
                    <p className="feature-work-description">
                      You need to create an account to find the best and
                      preferred intern.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 td">
                  <div class="feature-CardTwo">
                    <span class="number-list-two-two">02</span>
                    <div className="d-flex">
                      <div className="w-icon1">
                        <img src={wicon3} alt="" className="w-img-1" />
                      </div>
                      <h3 className="feature-work-title">
                        Search
                        <br />
                        Your intern
                      </h3>
                    </div>
                    <p className="feature-work-description">
                      You need to create an account to find the best and
                      preferred intern.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="feature-CardThree">
                    <span class="number-list-two-three">03</span>
                    <div className="d-flex">
                      <div className="w-icon1">
                        <img src={wicon2} alt="" className="w-img-1" />
                      </div>
                      <h3 className="feature-work-title">
                        Apply
                        <br />
                        For Dream intern
                      </h3>
                    </div>
                    <p className="feature-work-description">
                      After creating the account, you have to apply for the
                      desired intern.
                    </p>
                  </div>
                </div>
                <div class="col-md-6 td">
                  <div class="feature-CardFour">
                    <span class="number-list-two-four">04</span>
                    <div className="d-flex">
                      <div className="w-icon1">
                        <img src={wicon1} alt="" className="w-img-1" />
                      </div>
                      <h3 className="feature-work-title">
                        Create
                        <br />
                        Your Portfolio
                      </h3>
                    </div>
                    <p className="feature-work-description">
                      Create your portfolio after filling all the relevant
                      information
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWork;
