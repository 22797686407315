import React from "react";
import "../../Styles/GiveOffer.css";

const process = [
  {
    count: 1,
    step: "register",
    stepDown:
      "Molestie lorem est faucibus faucibus erat phasellus placerat proin aptent",
  },
  {
    count: 2,
    step: "We will contact you",
    stepDown:
      "We check that everything is in order. You will hear from us as soon as we have done so",
  },
  {
    count: 3,
    step: "You're up and running",
    stepDown:
      "As long as everything is fine, you will have access to everything you need to sell your product in the marketplace.",
  },
];

const GiveOffers = () => {
  return (
    <>
     
      <div class="cover">
        <div className="container pt-5">
          <div className="text-center pt-5">
          <h3 className="marketplace-title">Reach thousands of new customers </h3>
          <p className="marketplace-description">Become part of our new major investment. Then you can reach thousands of new customers<br/> through one of Africa's most famous brands.</p>
          <button className="offer-button my-2">
            Register your business
          </button>
          </div>
        </div>
        </div>
      {/* seocend section */}
      <div className="container">

        <div className="row justify-content-center">
          <div className="col-md-9">
            
      <div >
        <h3 className="marketplace-page-title mt-4">
          You only pay for what you sell
        </h3>
        <p className="marketplace-page-description pt-2">
          At LinkIntern Marketplace, it costs nothing to offer your products,
          nor are there any fixed expenses.
          The only thing you pay is a commission rate per product sold through
          us.
        </p>
      </div>

      <div>
        <h3 className="marketplace-page-title">We help your customers</h3>
        <p className="marketplace-page-description pt-2">
          By being part of LinkIntern Marketplace, you let our customer center
          help your customers. Then you get
          help from what is perhaps Africa's best customer service - then
          you can focus on other things.
         We promise to be good ambassadors for your company.
        </p>
      </div>

      <div>
        <h3 className="marketplace-page-title">
          Reach 750,000 customers every month
        </h3>
        <p className="marketplace-page-description pt-2">
          We market products offered at LinkIntern Marketplace.Then you and your
          company will have
          the opportunity to reach more than 750,000 potential customers.
        </p>
      </div>

      <div>
        <h3 className="marketplace-page-title">
          What does it take to become a seller with us?
        </h3>
        <p className="marketplace-page-description pt-2">
          For us, it is important that all sales people at LinkIntern
          Marketplace are serious and deliver good customer
           experiences. Therefore, everyone who offers their products with
          us must meet these requirements:.
        </p>
      </div>
      <div className="pb-5">
        <p>✔ All discounted products must be connected to College Students.</p>
        <p>✔ Be climate neutral or become so within one year.</p>
        <p>✔ The customer must be in focus.</p>
        <p>✔ Have fast order handling.</p>
        <p>✔ Sell quality products</p>
        <p>✔ All products must be safe to use for the customer</p>
        </div>


        </div>
        </div>

        </div>

      {/* section three */}

      <div className="offer-bg">
        <div className="container">
          <h3 className="text-center pb-4 service-title text-white">
            What happens now?
          </h3>

          <div class="row justify-content-center">
            {process.map((item) => (
              <div className="col-md-4 col-10 col-sm-8">
                <div class="service-1 text-center">
                  <h1>{item.count}</h1>
                  <h3>{item.step}</h3>
                  <p className="text-white">{item.stepDown}</p>
                </div>
              </div>
            ))}
          </div>
          <button className="d-block mx-auto offer-button offer-button-reverse  mt-3">
            Register your business
          </button>
        </div>
      </div>
    </>
  );
};

export default GiveOffers;
