/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/AppContext";
import '../../Styles/Navbar.css';
import logo from '../../assests/logo/logoTranparent.png'
import DashboardTopbar from "../Dashboard/DashboardTopbar";
import UserGuard from "../../pages/Auth/UserGuard";

function Navbar() {
  const { userCredentials } = useContext(UserContext)
  const [activeLink, setActiveLink] = useState('Home')
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setActiveLink(location.pathname === '/about-us' ? 'About Us' : location.pathname === '/contact' ? 'Contact' : location.pathname === '/job' ? 'Job' : location.pathname === '/marketplace' ? 'Marketplace' : location.pathname === '/super-admin' ? 'Admin' : 'Home')
  }, [location?.pathname])

  const redirectSignIn = value => {
    if (value === 'company') {
      localStorage.setItem('type', JSON.stringify('company'))
      navigate('/sign-in')
    }
    else {
      localStorage.setItem('type', JSON.stringify('intern'))
      navigate('/sign-in')
    }
  }

  return (
    <nav className="navbar px-0 py-0 navbar-expand-lg">
      <div className='container'>
        <div className={`d-flex align-items-center ${userCredentials?.email ? 'w-25' : 'w-45'}`}>
          <Link className="navbar-brand" to="/">
            <img src={logo} className="logo" alt='logo' />
          </Link>
          <UserGuard isShow={true}>
            <p className="nav-link text-dark d-none d-md-block pointer  mb-0" onClick={() => redirectSignIn('intern')}>
              Candidate
            </p>
            <p className="nav-link text-dark d-none d-md-block  pointer mb-0" onClick={() => redirectSignIn('company')}>
              Employer
            </p>
          </UserGuard>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="fas fa-bars toggler-icon"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse my-2" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <Link className={`nav-link ${activeLink === "Home" ? 'text-blue' : 'text-dark'}`} to="/">
                {activeLink === 'Home' ? "• Home" : 'Home'}
              </Link>
            </li> */}
            <UserGuard isShow={true}>
              <p className="nav-link text-dark d-md-none d-block pointer  mb-0" onClick={() => redirectSignIn('intern')}>
                Candidate
              </p>
              <p className="nav-link text-dark d-md-none d-block  pointer mb-0" onClick={() => redirectSignIn('company')}>
                Employer
              </p>
            </UserGuard>
            <UserGuard>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === "Job" ? 'text-blue' : 'text-dark'}`} to="/job">
                  {activeLink === 'Job' ? "• Jobs" : 'Jobs'}
                </Link>
              </li>
            </UserGuard>
            <UserGuard>
              <li className="nav-item">
                <Link className={`nav-link ${activeLink === "Marketplace" ? 'text-blue' : 'text-dark'}`} to='/marketplace' >
                  {activeLink === 'Marketplace' ? "• Marketplace" : 'Marketplace'}
                </Link>
              </li>
            </UserGuard>
            {
              userCredentials?.email === 'super.admin@gmail.com' ?
                <>
                  <li className="nav-item">
                    <Link className={`nav-link ${activeLink === "Admin" ? 'text-blue' : 'text-dark'}`} to="/super-admin">
                      {activeLink === 'Admin' ? "• Super Admin" : 'Super Admin'}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-dark" to="/dashboard">
                      Dashboard
                    </Link>
                  </li>
                </>

                : userCredentials?.email ?
                  <li className="nav-item">
                    <Link className="nav-link text-dark" to="/dashboard">
                      Dashboard
                    </Link>
                  </li>
                  :
                  <>
                    <Link onClick={() => localStorage.removeItem('type')} className="nav-link mr-2 text-dark mr- " to="/sign-in">
                      Login
                    </Link>
                    <Link to="/login-as">
                      <button className="nav-link text-dark main-button ml-md-2 px-3 py-2">
                        Signup
                      </button>
                    </Link>
                  </>
            }
          </ul>
          <UserGuard>
            <div className="">
              <DashboardTopbar />
            </div>
          </UserGuard>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
