import React from 'react';
import { useLocation, Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const userCredentials = JSON.parse(localStorage.getItem("@credentials"));
  localStorage.removeItem('type')

  return userCredentials?.email ? children : <Navigate to='/sign-in' state={location.pathname} />
};

export default PrivateRoute;