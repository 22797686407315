import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/AppContext";

function JobPostForm() {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate()
  const { allJobs, setAllJobs, userCredentials, setIsUpdated } = useContext(UserContext)
  const [jobData, setJobData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = e => {
    const updatedJob = { ...jobData }
    updatedJob[e.target.name] = e.target.value
    setJobData(updatedJob)

  }

  const handleJobPost = e => {
    e.preventDefault()
    setIsLoading(true)
    setAllJobs([...allJobs, { ...jobData, id: Math.round(Math.random() * 999999), company: userCredentials?.companyName, date: new Date().toLocaleString() }])
    fetch(`https://linkintern-backend.herokuapp.com/post-job?_id=${userCredentials?._id}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify([...allJobs, { ...jobData, id: Math.round(Math.random() * 999999), company: userCredentials?.companyName, date: new Date().toLocaleString() }])
    })
      .then(res => {
        if (res) {
          setIsLoading(false)
          e.target.reset()
          setIsUpdated(true)
          navigate('/job', { replace: true })
        }
      })
  }

  return (
    <>
      <div className="p-lg-4 p-3 my-4">
        <form className={`${screenWidth < 1024 ? "w-100" : "w-50 d-block mx-auto"}`} onSubmit={handleJobPost}>


          <div
            className='form-group'
          >
            <label for="jobTitle" className="lebel-title py-2">
              Job Title
            </label>
            <input
              onChange={handleChange}
              type="text"
              className="form-control w-100 py-4"
              id="jobTitle"
              name="jobTitle"
              placeholder="jobTitle"
              aria-describedby="jobTitle"
            />
          </div>

          <div
            className='form-group'
          >
            <label for="industry" className="lebel-title py-2">
              Sector/Industry
            </label>
            <select onChange={handleChange} className="form-control" id="industry" name="industry" required>
              <option disabled selected>Select Skills</option>
              <option>Finance Management & Ops</option>
              <option>Arts & Fashion</option>
              <option>Networking Opportunities</option>
              <option>Sales & Marketing</option>
              <option>Health & Science</option>
              <option>Product & Engineering</option>
              <option>Education</option>
              <option>Software Development</option>
              <option>Service</option>
              <option>Law & Journalism</option>
            </select>
          </div>

          <div
            className='form-group'
          >
            <label for="experience" className="lebel-title py-2">
              Entry Level
            </label>
            <select onChange={handleChange} className="form-control" id="experience" name='experience' required>
              <option disabled selected>Select Experience Level</option>
              <option>Entry Level</option>
              <option>Intermediate</option>
              <option>Expert</option>
            </select>
          </div>

          <div
            className='form-group'
          >
            <label for="position" className="lebel-title py-2">
              Job Nature
            </label>
            <select onChange={handleChange} className="form-control" id="position" name='position' required>
              <option disabled selected>Select Job Time</option>
              <option>Full Time</option>
              <option>Part Time</option>
              <option>Remote</option>
            </select>
          </div>

          <div
            className='form-group'
          >
            <label for="requirements" className="lebel-title py-2">
              Requirements
            </label>
            <textarea
              onChange={handleChange}
              className="form-control w-100 py-4"
              id="requirements"
              name="requirements"
              placeholder="Job Requirements"
              rows='5'
              required
            />
          </div>

          <div
            className='form-group'
          >
            <label for="description" className="lebel-title py-2">
              Roles/Job Description
            </label>
            <textarea
              onChange={handleChange}
              type="text"
              className="form-control w-100 py-4"
              id="description"
              name="description"
              placeholder="Job Description"
              rows='5'
              required
            />
          </div>

          <div
            className='form-group'
          >
            <label for="salary" className="lebel-title py-2">
              Salary Range
            </label>
            <select className="form-control" onChange={handleChange} id="salary" name='salary' required>
              <option disabled selected>Select Salary Range</option>
              <option>$100 - $300</option>
              <option>$300 - $500</option>
              <option>$500 - $1000</option>
              <option>$1000 - $1500</option>
              <option>$1500 - $3000</option>
            </select>
          </div>

          {
            !isLoading ?
              <button type='submit' className="next-button my-3">Post</button>
              :
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Uploading...</span>
                </div>
              </div>
          }
        </form>
      </div>
    </>
  );
}

export default JobPostForm;
