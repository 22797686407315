import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../context/AppContext';
import '../../Styles/SubscriptionPlan.css';

const subscriptionData = [
    {
        title: "Free",
        starting: "STARTS AT*",
        price: "$0",
        year: "/yr",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        subDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        bg: "free",
        color: "free-color",
        buttonBg: "free"
    },
    {
        title: "Standard",
        starting: "STARTS AT*",
        price: "$219",
        year: "/yr",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        subDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        bg: "standard",
        color: "standard-color",
        buttonBg: "standard"
    },
    {
        title: "Premium",
        starting: "STARTS AT*",
        price: "$400",
        year: "/yr",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        subDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        bg: "premium",
        color: "premium-color",
        buttonBg: "premium"
    },
]



function SubscripitonPlan() {
    const navigate = useNavigate()
    const { setStepperCount, setSubscription, userCredentials } = useContext(UserContext)


    const handleClick = value => {
        setStepperCount(2)
        setSubscription({ ...value })
        fetch(`https://linkintern-backend.herokuapp.com/subscription-update?_id=${userCredentials?._id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...value })
        })
            .then(res => {
                if (res) {
                    navigate('/dashboard/post-job', { replace: true })
                }
            })
    }

    return (
        <>
            <div className="center-card container p-lg-5 p-3 my-5">
                <h5 className="subscription-title">Which membership is right for you?</h5>

                <div className="row justify-content-center my-5">
                    {
                        subscriptionData.map(data =>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="card subscrip-card subscribe-border my-3 my-lg-0 mb-3">
                                    <div className={`card-header-bg  ${data.bg}`}></div>
                                    <div className="card-body text-success">
                                        <h5 className={`data-title ${data.color}`}>{data.title}</h5>
                                        <p className="start-at pt-3 mb-0">{data.starting}</p>
                                        <h5 className="price-title">{data.price}<span className="card-span">{data.year}</span></h5>
                                        <p className="price-text py-3">{data.description}</p>
                                        {/* <p className="mini-text">{data.subDescription}</p> */}
                                    </div>
                                    <div className="card-footer bg-transparent subscribe-border">
                                        <button className={`pricing-button ${data.title !== 'Free'? 'disabled': data.buttonBg}`} onClick={() => handleClick(data)}>Get Started</button>
                                    </div>
                                </div>
                            </div>)
                    }
                </div>

            </div>
        </>
    )
}

export default SubscripitonPlan
