import React, { useContext, useEffect, useState } from "react";
import Footer from "../../components/Common/Footer";
import Navbar from "../../components/Common/Navbar";
import { UserContext } from "../../context/AppContext";
import "../../Styles/MarketPlace.css";
import MarketplaceCard from "./MarketplaceCard";
import FooterTwo from "../../components/Common/FooterTwo";


const Marketplace = () => {
  const [allOffer, setAllOffer] = useState([])

  useEffect(() => {
    fetch('https://linkintern-backend.herokuapp.com/get-offer')
      .then(res => res.json())
      .then(data => {
        if (data?.length) {
          setAllOffer(data);

        }
      })
  }, [])

  return (
    <>
      <Navbar />
      <div className="marketplace-section ">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-4">
            <div class="card">
              <h5 class="card-header">Post an offer</h5>
              <div class="card-body">
                <h5 class="card-title text-dark">Company Name</h5>
                <p class=" text-dark">Dissount Offer</p>
                <button href="" class="btn btn-primary">Post offer</button>
              </div>
            </div>
      
            </div>
            <div className="col-md-8">
              <h6 className="marketplace-header-title pt-3 p-md-0">
              Do you want to be part of LinkIntern Marketplace?
              </h6>
              <h3 className="marketplace-title pt-2">
              LinkIntern Marketplace is a Marketplace for Companies offering College Students discounted products like Laptops, Mobile, Driving Lessons among others.
              </h3>
              <p className="marketplace-description py-3">
              Giving students a discount isn’t just charity, it’s good business. By making a student discount or special offer available on our site you’ll stand out from competing brands and win customers for life. With today’s subscription economy and a shift towards services, first impressions with student consumers is all the more important.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="marketpace-service ">
        <div className="container">
          <h3 className="marketplace-page-title pt-3">Dive Deeper</h3>
          <p className="marketplace-page-description pb-3">Learn more about Services Marketplace and how to get started with our guides and resources</p>
          <div className="row">
            {
              allOffer.map(service => <div className="col-lg-3 col-md-6 my-3">
                <MarketplaceCard service={service} />
              </div>)
            }
          </div>
        </div>

      </div>
      <FooterTwo />
    </>
  );
};

export default Marketplace;
