import React from 'react';
import Iframe from '@trendmicro/react-iframe';

function Map() {
    const destination = "Sylhet"
    const screenWidth = window.innerWidth;

    return (
        <>
              <Iframe
              src={`https://maps.google.com/maps?q=${destination},BANGLADESH&t=&z=16&ie=UTF8&iwloc=&output=embed`}
            style={{height: `${screenWidth <1500 ? '27vh' : '18vh'}`, width: '100%', borderRadius: '12px'}}
        /> 
        </>
    )
}

export default Map
