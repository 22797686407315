import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'

const CreateOffer = () => {
  const [file, setFile] = useState(null)
  const [offerData, setOfferData] = useState({})
  const navigate = useNavigate();

  const handleOffer = e => {
    const updatedOffer = { ...offerData };
    updatedOffer[e.target.name] = e.target.value;
    setOfferData(updatedOffer)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    fetch('https://linkintern-backend.herokuapp.com/create-offer', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(offerData)
    })
      .then(res => res.json())
      .then(data => {
        if (data) {
          e.target.reset();
          navigate('/marketplace');
        }
      })
  }


  return (
    <>
      <div>
        <h1 className="pb-3">Create Offer</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label for="companyName" className="lebel-title py-2">
              Company Name
            </label>
            <input
              onChange={handleOffer}
              type="text"
              className="form-control  py-4"
              id="companyName"
              name="companyName"
              placeholder="Company Name"
              aria-describedby="companyName"
              required
            />
          </div>
          <div className="form-group">
            <label for="offerTitle" className="lebel-title py-2">
              Offer Title
            </label>
            <textarea
              onChange={handleOffer}
              type="text"
              className="form-control w-100 py-4"
              id="offerTitle"
              name="offerTitle"
              placeholder="Offer Title"
              rows="5"
              required
            />
          </div>

          <div className="form-group">
            <label for="siteLink" className="lebel-title py-2">
              Offer Link
            </label>
            <textarea
              onChange={handleOffer}
              type="text"
              className="form-control w-100 py-4"
              id="siteLink"
              name="siteLink"
              placeholder="Offer Link"
              rows="5"
              required
            />
          </div>

          <div className="form-group">
            <label for="cupon" className="lebel-title py-2">
              Coupon Code
            </label>
            <input
              onChange={handleOffer}
              type="text"
              className="form-control  py-4"
              id="cupon"
              name="cupon"
              placeholder="Cupon Code"
              aria-describedby="cupon"
            />
          </div>

          <div className="form-group">
            <label for="offerImage" className="lebel-title py-2">
              Upload Thumbnail
            </label>
            <br />{" "}
            <input
              onChange={e => setFile(e.target.files[0])}
              type="file"
              className="custom-file-upload"
              id="offerImage"
              name="offerImage"
            />
          </div>

          <button type='submit' className="btn main-button px-4 my-4">Submit</button>
        </form>
      </div>
    </>
  );
};

export default CreateOffer;
